import React, { FC, useEffect, useRef, useState } from 'react'
import ConfirmHcaptcha from '@hcaptcha/react-native-hcaptcha'
import { Text, TouchableOpacity, View } from 'react-native'
import * as config from '../../app/config'
import { Checkbox } from 'react-native-paper'
import { useTranslation } from 'react-i18next'

interface NativeHcaptchaProps {
  onSetCode?: (code: string) => void
  onSetIsVerifiedHuman?: (isHuman: boolean) => void
}

export const NativeHcaptcha: FC<NativeHcaptchaProps> = ({ onSetCode, onSetIsVerifiedHuman }) => {
  const { t, i18n } = useTranslation()
  const [checked, setChecked] = useState(false)
  const captchaForm = useRef<ConfirmHcaptcha | null>(null)

  useEffect(() => {
    onSetCode?.('')
  }, [])

  const onPress = (): void => {
    if (checked) return
    captchaForm.current?.show?.()
  }

  const onMessage = (event): void => {
    if (event && event.nativeEvent.data) {
      if (['cancel'].includes(event.nativeEvent.data)) {
        console.log('unsuccessful hCaptcha', event.nativeEvent.data)
        captchaForm.current?.hide?.()
        onSetIsVerifiedHuman?.(false)
        setChecked(false)
      } else if (['error', 'expired'].includes(event.nativeEvent.data)) {
        console.log('unsuccessful hCaptcha', event.nativeEvent.data)
        captchaForm.current?.hide?.()
        onSetIsVerifiedHuman?.(false)
        setChecked(false)
      } else {
        console.log('Verified code from hCaptcha', event.nativeEvent.data)
        captchaForm.current?.hide?.()
        onSetCode?.(event.nativeEvent.data)
        onSetIsVerifiedHuman?.(true)
        setChecked(true)
      }
    }
  }

  return <View>
    <ConfirmHcaptcha
      ref={_ref => (captchaForm.current = _ref)}
      siteKey={config.hcaptchaSitekey}
      baseUrl={config.hcaptchaBaseurl}
      languageCode={i18n.language}
      onMessage={onMessage}
      size={'invisible'}
    />
    <TouchableOpacity onPress={onPress} style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Checkbox status={checked ? 'checked' : 'unchecked'}/>
      <Text>{t('captcha.i_am_human', 'I am human')}</Text>
    </TouchableOpacity>
  </View>
}
