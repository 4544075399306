import Constants from 'expo-constants'

export const stringToBoolean = (value: any): boolean => {
  const boolFalse = ['false', '0', 'no', 'n', 'off', '']
  return typeof value === 'string' && !boolFalse.includes(value.trim().toLowerCase())
}

export const appName: string = Constants.expoConfig?.name?.toString() ?? 'Distributed Library'
export const apiUrl: string = Constants.expoConfig?.extra?.apiUrl?.toString() ?? 'http://localhost:8080'
export const useAndroidAppInfo: boolean = stringToBoolean(Constants.expoConfig?.extra?.useAndroidAppInfo)
export const androidAppInfoUrl: string = Constants.expoConfig?.extra?.androidAppInfoUrl?.toString() ?? 'https://hennekedevelopment.nl/.distributed-library/android-app-info-dev.json'
export const version: string = Constants.expoConfig?.extra?.version?.toString() ?? '0.0.0'
export const versionCode: number = parseInt(Constants.expoConfig?.extra?.versionCode?.toString() ?? '0')
export const debugLoginUser: string | undefined = Constants.expoConfig?.extra?.debugLoginUser?.toString()
export const debugLoginPass: string | undefined = Constants.expoConfig?.extra?.debugLoginPass?.toString()
export const debugSimulateScan: boolean = stringToBoolean(Constants.expoConfig?.extra?.debugSimulateScan)
export const debugSimulateScanOk: string = Constants.expoConfig?.extra?.debugSimulateScanOk?.toString() ?? '9781583947241'
export const debugSimulateScanFail: string = Constants.expoConfig?.extra?.debugSimulateScanFail?.toString() ?? '0000000000123'
export const newrelicEnabled: boolean = stringToBoolean(Constants.expoConfig?.extra?.newrelicEnabled)
export const newrelicAndroidAppToken: string = Constants.expoConfig?.extra?.newrelicAndroidAppToken?.toString() ?? ''
export const newrelicIosAppToken: string = Constants.expoConfig?.extra?.newrelicIosAppToken?.toString() ?? ''
export const enableHeaderXClientVersion: boolean = stringToBoolean(Constants.expoConfig?.extra?.enableHeaderXClientVersion)
export const featureToggleAllowRegistration: boolean = stringToBoolean(Constants.expoConfig?.extra?.featureToggleAllowRegistration)
export const hcaptchaSitekey: string = Constants.expoConfig?.extra?.hcaptchaSitekey?.toString() ?? ''
export const hcaptchaBaseurl: string = Constants.expoConfig?.extra?.hcaptchaBaseurl?.toString() ?? 'https://hcaptcha.com'
export const hcaptchaRequired: boolean = stringToBoolean(Constants.expoConfig?.extra?.hcaptchaRequired)
