import React, { FC, useContext, useState } from 'react'
import { Text, View } from 'react-native'
import { Button, TextInput, Title } from 'react-native-paper'
import { useNavigation } from '@react-navigation/native'
import { ApiAuthContext } from '../../app/api_auth_context'
import { capitalizeFirstLetter } from '../../app/text'
import { DummyCaptcha } from '../captcha/DummyCaptcha'
import { Hcaptcha } from '../captcha/Hcaptcha'
import * as config from '../../app/config'
import { useTranslation } from 'react-i18next'

export const SignupScreen: FC = () => {
  const [inputEmail, setInputEmail] = useState('')
  const [inputPassword, setInputPassword] = useState('')
  const [inputPasswordConfirm, setInputPasswordConfirm] = useState('')
  const [passwordHidden, setPasswordHidden] = useState(true)
  const [passwordConfirmHidden, setPasswordConfirmHidden] = useState(true)
  const [signupError, setSignupError] = useState<string | undefined>(undefined)
  const [captchaCode, setCaptchaCode] = useState<string>('')
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false)
  const { apiClient } = useContext(ApiAuthContext)
  const navigation = useNavigation()
  const { t } = useTranslation()
  const captchaType = config.hcaptchaRequired ? 'hcaptcha' : 'dummy'

  const trCancel = t('signup.log_in_instead', 'Log in instead')
  const trTitle = t('signup.create_new_account', 'Create New Account')
  const trSignup = t('signup.register', 'Register')

  const cancel = (): void => {
    navigation.goBack()
  }

  const onSignupFormSubmit = (): void => {
    const cleanInputEmail = inputEmail.trim()

    if (inputPassword !== inputPasswordConfirm) {
      return
    }

    if (!isCaptchaVerified) {
      return
    }

    apiClient?.createAccount(cleanInputEmail, inputPassword, captchaType, captchaCode)
      .then(res => {
        // @ts-expect-error
        navigation.navigate('congratulations')
      })
      .catch(reason => {
        setSignupError(capitalizeFirstLetter(reason.message ?? reason.toString()))
      })
  }

  const createConditionsOk = inputEmail.includes('@') && inputPassword === inputPasswordConfirm && inputPassword.length >= 8 && isCaptchaVerified

  return <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Title style={{ textAlign: 'center', marginBottom: 15 }}>{trTitle}</Title>
        <TextInput value={inputEmail} label={t('signup.email_label', 'Email')} mode={'outlined'} style={{ width: '80%', maxWidth: 300 }}
                   onChangeText={setInputEmail} autoComplete={'username'} importantForAutofill={'yes'}
                   autoCapitalize={'none'} error={signupError !== undefined && !inputEmail.includes('@')}
                   keyboardType={'email-address'} textContentType={'username'}></TextInput>
        <TextInput style={{ marginTop: 10, width: '80%', maxWidth: 300 }} value={inputPassword} label={t('signup.password_label', 'Password')}
                   mode={'outlined'} autoCapitalize={'none'}
                   onChangeText={setInputPassword} autoComplete={'password'} importantForAutofill={'yes'}
                   secureTextEntry={passwordHidden} error={signupError !== undefined && inputPassword.length < 8}
                   right={<TextInput.Icon icon={passwordHidden ? 'eye' : 'eye-off'}
                                          onPress={() => setPasswordHidden(!passwordHidden)}/>}
                   textContentType={'password'}></TextInput>
        <TextInput style={{ marginTop: 10, width: '80%', maxWidth: 300 }} value={inputPasswordConfirm} label={t('signup.password_confirmation_label', 'Password (confirmation)')}
               mode={'outlined'} autoCapitalize={'none'}
               onChangeText={setInputPasswordConfirm} autoComplete={'password'} importantForAutofill={'yes'}
               secureTextEntry={passwordConfirmHidden} error={inputPassword !== inputPasswordConfirm}
               right={<TextInput.Icon icon={passwordConfirmHidden ? 'eye' : 'eye-off'}
                                      onPress={() => setPasswordConfirmHidden(!passwordConfirmHidden)}/>}
               textContentType={'password'}></TextInput>

        <View style={{ marginTop: 15 }}>
          {captchaType === 'hcaptcha' && <Hcaptcha onSetCode={setCaptchaCode} onSetIsVerifiedHuman={setIsCaptchaVerified}></Hcaptcha>}
          {captchaType === 'dummy' && <DummyCaptcha onSetCode={setCaptchaCode} onSetIsVerifiedHuman={setIsCaptchaVerified}></DummyCaptcha>}
        </View>

        <Button style={{ marginTop: 15, marginBottom: 15, width: '80%', maxWidth: 300 }} mode={'contained'}
                onPress={onSignupFormSubmit} disabled={!createConditionsOk}>{trSignup}</Button>
        <Button style={{ margin: 15 }} onPress={cancel}>{trCancel}</Button>
        {(signupError !== undefined) ? <Text>{signupError}</Text> : <></>}
    </View>
}
