export const parseRFC3339DateTime = (s: string): Date => {
  // FIXME do not rely on builtin Date constructor to parse RFC3339 dates
  return new Date(s)
}

export const strfmtDateOnly = (d: Date): string => {
  return d.toISOString().split('T')[0]
}

export const strfmtTimeOnly = (d: Date): string => {
  const hhmmss = d.toISOString().split('T')[1].split('.')[0]
  const hhmm = hhmmss.substring(0, hhmmss.lastIndexOf(':'))
  return hhmm
}

export const strfmtDateTime = (d: Date): string => {
  return strfmtDateOnly(d) + ' ' + strfmtTimeOnly(d)
}

export const isToday = (d: Date): boolean => {
  const now = new Date()
  return d.getFullYear() === now.getFullYear() && d.getMonth() === now.getMonth() && d.getDate() === now.getDate()
}

export const isThisYear = (d: Date): boolean => {
  const now = new Date()
  return d.getFullYear() === now.getFullYear()
}

export const shortMonthName = (zeroBasedMonthNumber: number): string => {
  return [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ][zeroBasedMonthNumber]
}
