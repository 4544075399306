/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateAccountRequest,
  CreateAccountResponse,
  ErrorResponse,
  GetAuthUserResponse,
  LoginRequest,
  LoginResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Auth<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name CheckEmailToken
   * @request GET:/auth/check-email-token
   */
  checkEmailToken = (params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/auth/check-email-token`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @name ConfirmAccount
   * @request GET:/auth/confirm-account/{id}/{token}
   */
  confirmAccount = (id: string, token: string, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/auth/confirm-account/${id}/${token}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @name CreateAccount
   * @request POST:/auth/create-account
   */
  createAccount = (Body: CreateAccountRequest, params: RequestParams = {}) =>
    this.request<CreateAccountResponse, ErrorResponse>({
      path: `/auth/create-account`,
      method: "POST",
      body: Body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name Login
   * @request POST:/auth/login
   */
  login = (Body: LoginRequest, params: RequestParams = {}) =>
    this.request<LoginResponse, ErrorResponse>({
      path: `/auth/login`,
      method: "POST",
      body: Body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name GetAuthUser
   * @request GET:/auth/user
   */
  getAuthUser = (params: RequestParams = {}) =>
    this.request<GetAuthUserResponse, ErrorResponse>({
      path: `/auth/user`,
      method: "GET",
      format: "json",
      ...params,
    });
}
