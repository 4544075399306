const names = {
  NLD: 'Netherlands'
}

export const getCountryName = (alpha3Code: string): string => {
  const name: string | undefined = names[alpha3Code]

  if (name === undefined) return alpha3Code

  return name
}
