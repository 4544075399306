import React, { FC, useContext, useEffect, useState } from 'react'
import { ApiAuthContext } from '../../app/api_auth_context'
import { Text, View } from 'react-native'
import { IncomingRequest, PickupSlot } from '../../client/data-contracts'
import { Button } from 'react-native-paper'
import { BookRequest, getRequestedByUserNameFromBookRequestInfo } from './book'
import { PickupSlotsInput } from './PickupSlots'
import { parseRFC3339DateTime, strfmtDateOnly, strfmtDateTime } from '../../app/dates'
import { useTranslation } from 'react-i18next'

interface IncomingRequestBoxProps {
  request: IncomingRequest
  onRequestUpdate: () => void
}

export const IncomingRequestBox: FC<IncomingRequestBoxProps> = ({ request, onRequestUpdate }) => {
  const { apiClient } = useContext(ApiAuthContext)
  const { t } = useTranslation()

  const [availablePickupSlots, setAvailablePickupSlots] = useState<PickupSlot[]>([])
  const [requestedByUserName, setRequestedByUserName] = useState('')
  const [askBookBackVisible, setAskBookBackVisible] = useState(false)
  const [availableReturnSlots, setAvailableReturnSlots] = useState<PickupSlot[]>([])

  useEffect(() => {
    void apiClient?.bookRequestInfo(request.id)
      .then(info => {
        setRequestedByUserName(getRequestedByUserNameFromBookRequestInfo(info))
      })
      .catch(() => setRequestedByUserName(''))
  }, [])

  const accept = (): void => {
    apiClient?.acceptBookRequest(request as unknown as BookRequest).finally(() => onRequestUpdate())
  }

  const reject = (): void => {
    apiClient?.rejectBookRequest(request as unknown as BookRequest).finally(() => onRequestUpdate())
  }

  const askBookBack = (): void => {
    setAskBookBackVisible(true)
  }

  const header = <Text style={{
    fontSize: 12,
    color: '#999',
    marginBottom: 5
  }}>{strfmtDateOnly(parseRFC3339DateTime(request.created_at))} {requestedByUserName}</Text>

  if (request.status === 'pending') {
    return <View>
      {header}
      <Text>{t('incoming_request_box.name_asks_to_borrow_this_book', '{{name}} asks to borrow this book.', { name: requestedByUserName })}</Text>
      <View style={{ flexDirection: 'row', marginTop: 5 }}>
        <Button onPress={accept} mode={'contained'}>{t('incoming_request_box.accept', 'Accept')}</Button>
        <Button onPress={reject} mode={'outlined'} style={{ marginLeft: 5 }}>{t('incoming_request_box.reject', 'Reject')}</Button>
      </View>
    </View>
  }

  if (request.status === 'rejected') {
    return <>
      {header}
      <Text>{t('incoming_request_box.you_rejected_request', 'You have rejected this request')}</Text>
    </>
  }

  if (request.status === 'cancelled') {
    return <>
      {header}
      <Text>{t('incoming_request_box.request_cancelled', 'This request has been cancelled')}</Text>
    </>
  }

  if (request.status === 'accepted' || request.status === 'no-feasible-pickup-slots') {
    const save = (): void => {
      apiClient?.setAvailablePickupSlots(request.id, availablePickupSlots)
        // TODO erase fun console.log message
        .then(() => console.log('megasuccess'))
        .catch(reason => console.error(reason))
        .finally(() => onRequestUpdate())
    }

    const onChangeValue = (pickupSlots: PickupSlot[]): void => {
      setAvailablePickupSlots(pickupSlots)
    }

    const canSave = availablePickupSlots.length !== 0

    return <View>
      {header}
      {request.status === 'no-feasible-pickup-slots' &&
        <Text style={{ marginBottom: 15 }}>{t('incoming_request_box.no_feasible_pickup_slots', 'Unfortunately, {{name}} is not available on any of your previously chosen pickup slots.', { name: requestedByUserName })}</Text>
      }
      <Text>{t('incoming_request_box.when_can_user_pick_up_book', 'When can {{name}} pick up your book?', { name: requestedByUserName })}</Text>
      <View style={{ marginTop: 15 }}>
        <PickupSlotsInput onChangeValue={onChangeValue}></PickupSlotsInput>
      </View>
      <Button mode={'outlined'} onPress={save} style={{ marginTop: 15 }} disabled={!canSave}>{t('common.save', 'Save')}</Button>
    </View>
  }

  if (request.status === 'has-available-pickup-slots') {
    const cancelPickupSlots = (): void => {
      void apiClient?.removeAvailablePickupSlots(request.id)
        .then(() => {
          onRequestUpdate()
        })
        .catch(reason => console.error(reason))
    }

    return <View>
      {header}
      <Text>{t('incoming_request_box.you_have_saved_pickup_options', 'You saved the following options for pickup:')}</Text>
      <View>
        {request.available_pickup_slots?.map((slot, index) => {
          return <View
            key={index}><Text>{strfmtDateTime(parseRFC3339DateTime(slot.from))} - {strfmtDateTime(parseRFC3339DateTime(slot.until))}</Text></View>
        })}
      </View>
      <Text>{t('incoming_request_box.user_will_select_option', '{{name}} will select an option.', { name: requestedByUserName })}</Text>
      <Button mode={'outlined'} onPress={cancelPickupSlots} style={{ marginTop: 15 }}>{t('common.cancel', 'Cancel')}</Button>
    </View>
  }

  if (request.status === 'has-chosen-pickup-slot') {
    const cancelPickupSlots = (): void => {
      void apiClient?.removeAvailablePickupSlots(request.id)
        .then(() => {
          onRequestUpdate()
        })
        .catch(reason => console.error(reason))
    }

    const markBookRequestPickedUp = (): void => {
      apiClient?.markBookRequestPickedUp(request.id)
        .catch(reason => console.error(reason))
        .finally(() => onRequestUpdate())
    }

    const strmftDateTime = (date: string | undefined): string => {
      try {
        return strfmtDateTime(parseRFC3339DateTime(date ?? ''))
      } catch (e) {
        return ''
      }
    }

    const from = strmftDateTime(request.chosen_pickup_slot?.from)
    const until = strmftDateTime(request.chosen_pickup_slot?.until)

    return <View>
      {header}
      <Text>{t('incoming_request_box.user_will_pickup_the_book', '{{name}} will pickup the book:', { name: requestedByUserName })}</Text>
      <Text>{from} - {until}</Text>
      <Button onPress={markBookRequestPickedUp} mode={'outlined'}>{t('incoming_request_box.book_has_been_picked_up', 'Book has been picked up')}</Button>
      <Button onPress={cancelPickupSlots} mode={'outlined'} style={{ marginTop: 5 }}>{t('common.cancel', 'Cancel')}</Button>
    </View>
  }

  if (request.status === 'picked-up' || request.status === 'no-feasible-return-slots') {
    const markBookRequestReturned = (): void => {
      apiClient?.markBookRequestReturned(request.id)
        .catch(reason => console.error(reason))
        .finally(() => onRequestUpdate())
    }

    const onChangeValue = (returnSlots: PickupSlot[]): void => {
      setAvailableReturnSlots(returnSlots)
    }

    const save = (): void => {
      apiClient?.setAvailableReturnSlots(request.id, availableReturnSlots)
        // TODO erase fun console.log message
        .then(() => console.log('megasuccess'))
        .catch(reason => console.error(reason))
        .finally(() => onRequestUpdate())
    }

    const canSave = availableReturnSlots.length !== 0

    return <>
      {header}
      {request.status === 'picked-up' && !askBookBackVisible && <View>
          <Text>{t('incoming_request_box.book_has_been_picked_up_by_user', 'Book has been picked up by {{name}}', { name: requestedByUserName })}</Text>
          <Button onPress={askBookBack} mode={'outlined'} style={{ marginTop: 15 }}>{t('incoming_request_box.ask_book_back', 'Ask book back')}</Button>
          <Button onPress={markBookRequestReturned} mode={'outlined'} style={{ marginTop: 15 }}>{t('incoming_request_box.book_has_been_returned_to_you', 'Book has been returned to you')}</Button>
        </View>
      }
      {(request.status === 'no-feasible-return-slots' || askBookBackVisible) && <View>
        {request.status === 'no-feasible-return-slots' && <Text style={{ marginBottom: 15 }}>{t('incoming_request_box.no_feasible_return_slots', 'Unfortunately, {{name}} is not available on any of your previously chosen return slots.', { name: requestedByUserName })}</Text>}
        <Text>{t('incoming_request_box.when_can_user_return_book', 'When can {{requestedByUserName}} return your book?', { name: requestedByUserName })}</Text>
        <View style={{ marginTop: 15 }}>
          <PickupSlotsInput onChangeValue={onChangeValue}></PickupSlotsInput>
        </View>
        <Button mode={'outlined'} onPress={save} style={{ marginTop: 15 }} disabled={!canSave}>{t('common.save', 'Save')}</Button>
        {request.status === 'picked-up' && <Button mode={'text'} onPress={() => setAskBookBackVisible(false)} style={{ marginTop: 5 }} disabled={!canSave}>{t('common.cancel', 'Cancel')}</Button>}
      </View>}
    </>
  }

  if (request.status === 'has-available-return-slots') {
    const cancelReturnSlots = (): void => {
      void apiClient?.removeAvailableReturnSlots(request.id)
        .then(() => {
          onRequestUpdate()
        })
        .catch(reason => console.error(reason))
    }

    return <View>
      {header}
      <Text>{t('incoming_request_box.you_have_saved_these_return_options', 'You saved the following options for return:')}</Text>
      <View>
        {request.available_pickup_slots?.map((slot, index) => {
          return <View
            key={index}><Text>{strfmtDateTime(parseRFC3339DateTime(slot.from))} - {strfmtDateTime(parseRFC3339DateTime(slot.until))}</Text></View>
        })}
      </View>
      <Text>{t('incoming_request_box.user_will_select_option', '{{name}} will select an option.', { name: requestedByUserName })}</Text>
      <Button mode={'outlined'} onPress={cancelReturnSlots} style={{ marginTop: 15 }}>{t('common.cancel', 'Cancel')}</Button>
    </View>
  }

  if (request.status === 'has-chosen-return-slot') {
    const cancelReturnSlots = (): void => {
      void apiClient?.removeAvailableReturnSlots(request.id)
        .then(() => {
          onRequestUpdate()
        })
        .catch(reason => console.error(reason))
    }

    const markBookRequestReturned = (): void => {
      apiClient?.markBookRequestReturned(request.id)
        .catch(reason => console.error(reason))
        .finally(() => onRequestUpdate())
    }

    const strmftDateTime = (date: string | undefined): string => {
      try {
        return strfmtDateTime(parseRFC3339DateTime(date ?? ''))
      } catch (e) {
        return ''
      }
    }

    const from = strmftDateTime(request.chosen_pickup_slot?.from)
    const until = strmftDateTime(request.chosen_pickup_slot?.until)

    return <View>
      {header}
      <Text>{t('incoming_request_box.user_will_return_the_book', '{{name}} will return the book:', { name: requestedByUserName })}</Text>
      <Text>{from} - {until}</Text>
      <Button onPress={markBookRequestReturned} mode={'outlined'}>{t('incoming_request_box.book_has_been_returned', 'Book has been returned')}</Button>
      <Button onPress={cancelReturnSlots} mode={'outlined'}>{t('common.cancel', 'Cancel')}</Button>
    </View>
  }

  if (request.status === 'returned') {
    return <>
      {header}
      <Text>{t('incoming_request_box.book_has_been_returned_to_you', 'Book has been returned to you')}</Text>
    </>
  }

  return <></>
}
