import { FC } from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { LoginBox2 } from './LoginBox2'
import { SignupScreen } from './SignupScreen'
import { CongratulationsScreen } from './CongratulationsScreen'

const Stack = createNativeStackNavigator()

interface AccountNavigatorProps {
  onLoginSuccess: (accessToken: string) => void
}

export const AccountNavigator: FC<AccountNavigatorProps> = ({ onLoginSuccess }) => {
  return (
        <Stack.Navigator screenOptions={{ animation: 'fade', headerShown: false }}>
            <Stack.Screen name={'login'} children={() => <LoginBox2 onLoginSuccess={onLoginSuccess}/>}/>
            <Stack.Screen name={'signup'} children={() => <SignupScreen/>} />
            <Stack.Screen name={'congratulations'} children={() => <CongratulationsScreen/>} />
        </Stack.Navigator>
  )
}
