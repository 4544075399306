import { createContext } from 'react'

export type BookId = string
export type Base64Image = string
export const CoverNotFound: string = 'NOT_FOUND'

export interface CoverCacheContextValue {
  cache: Record<BookId, Base64Image>
  addCache: (bookId: BookId, base64Image: Base64Image) => void
  setNotFound: (bookId: BookId) => void
}

const defaultValue: CoverCacheContextValue = {
  cache: {},
  addCache: () => {},
  setNotFound: () => {}
}

export const CoverCacheContext = createContext<CoverCacheContextValue>(defaultValue)
