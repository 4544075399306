import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { FC, useContext, useEffect, useState } from 'react'
import { JsonPickupLocationWithId } from '../../client/data-contracts'
import { ApiAuthContext } from '../../app/api_auth_context'
import { ScrollView, View } from 'react-native'
import { Button, RadioButton, Snackbar, Text, Title } from 'react-native-paper'
import { PrettyTextInput } from '../widgets/PrettyTextInput'
import Ionicons from '@expo/vector-icons/Ionicons'
import { useCustomTheme } from '../../app/themes'
import { askForPermissions, getLocationPermissionStatus, LocationPermissionStatus } from '../../app/location'
import { useTranslation } from 'react-i18next'
import AsyncStorage from '@react-native-async-storage/async-storage'

export interface ProfileScreenProps {
  onLogout: () => void
  onLocationPermissionChange: (response: LocationPermissionStatus) => void
}

const ProfileStack = createNativeStackNavigator()

export const ProfileScreen: FC<ProfileScreenProps> = ({ onLogout, onLocationPermissionChange }) => {
  const [pickupLocation, setPickupLocation] = useState<JsonPickupLocationWithId | null>(null)
  const [address, setAddress] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [locality, setLocality] = useState('')
  const [notes, setNotes] = useState('')
  const [country, setCountry] = useState('Netherlands')
  const [busy, setBusy] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | null>(null)
  const [locationPermissionStatus, setLocationPermissionStatus] = useState<LocationPermissionStatus | null>(null)
  const { t, i18n } = useTranslation()

  const { apiClient, authUser } = useContext(ApiAuthContext)

  useEffect(() => {
    setBusy(true)
    void apiClient?.getSingletonPickupLocation().then(value => {
      setPickupLocation(value as JsonPickupLocationWithId)
      setAddress(value.address ?? '')
      setPostalCode(value.postal_code ?? '')
      setLocality(value.locality ?? '')
      setNotes(value.notes ?? '')
    }).finally(() => setBusy(false))
  }, [])

  useEffect(() => {
    void getLocationPermissionStatus()
      .then(value => {
        setLocationPermissionStatus(value)
        onLocationPermissionChange(value)
      })
  }, [])

  const save = (): void => {
    setBusy(true)
    if (pickupLocation !== null) {
      pickupLocation.address = address
      pickupLocation.postal_code = postalCode
      pickupLocation.locality = locality
      pickupLocation.notes = notes
      void apiClient?.updateSingletonPickupLocation(pickupLocation)
        .then(() => setToastMessage(t('common.saved_toast_message', 'Saved!')))
        .catch(reason => setToastMessage(reason.toString()))
        .finally(() => setBusy(false))
    }
  }

  const onClickGrantLocationPermission = async (): Promise<void> => {
    const response = await askForPermissions()
    const value = response.status as LocationPermissionStatus
    setLocationPermissionStatus(value)
    onLocationPermissionChange(value)
    if (value !== 'granted') {
      setToastMessage(t('profile.permission_not_granted_toast', 'Permission not granted'))
    }
  }

  const theme = useCustomTheme()

  const changeLanguage = (newLng: string): void => {
    void i18n.changeLanguage(newLng)
    void AsyncStorage.setItem('@preferredLanguage', newLng)
      .catch(reason => {
        console.log('could not save preferred language to AsyncStorage', reason)
      })
  }

  return <ProfileStack.Navigator>
    <ProfileStack.Screen name={'ProfileHome'} options={{ title: t('tabs.profile_title') }} children={() => (
      <>
        <ScrollView>
          <View style={{ padding: 15 }}>
            <Title><Ionicons name={'location-outline'} size={22}
                             color={theme.colors.secondary}/> {t('profile.pickup_address_title', 'Pickup address')}
            </Title>
            <Text
              style={{ margin: 15 }}>{t('profile.pickup_address_description', 'Let others know where they can pick up your books. They will only see this after you\'ve accepted their loan request.')}</Text>
            <View>
              <PrettyTextInput value={address} onChangeText={setAddress}
                               label={t('profile.street_and_house_number', 'Street and house number')}
                               onFocus={() => setToastMessage(null)}></PrettyTextInput>
              <PrettyTextInput value={postalCode} onChangeText={setPostalCode}
                               label={t('profile.postal_code', 'Postal Code')}
                               onFocus={() => setToastMessage(null)}></PrettyTextInput>
              <PrettyTextInput value={locality} onChangeText={setLocality} label={t('profile.locality', 'Locality')}
                               onFocus={() => setToastMessage(null)}></PrettyTextInput>
              <PrettyTextInput value={country} onChangeText={setCountry} label={t('profile.country', 'Country')}
                               editable={false}></PrettyTextInput>
              <PrettyTextInput value={notes} onChangeText={setNotes} label={'Notes'}
                               onFocus={() => setToastMessage(null)}
                               placeholder={t('profile.notes_placeholder', 'e.g. ring the square bell')}></PrettyTextInput>
              <Button style={{ marginTop: 10 }} onPress={save} disabled={busy} mode={'contained'}
                      loading={busy}>{t('common.save', 'Save')}</Button>
            </View>

            <Title style={{ paddingTop: 30 }}><Ionicons name={'checkmark-circle-outline'} size={22}
                                                      color={theme.colors.secondary}/> {t('profile.permissions_title', 'Permissions')}
            </Title>
            {/* TODO abstract this into <LocationGrant/> or something similar */}
            {locationPermissionStatus === 'granted' &&
              <Text
                style={{ margin: 15 }}>{t('profile.location_permission_granted', 'Location permission granted.')}</Text>}
            {locationPermissionStatus === 'undetermined' &&
              <Button style={{ marginVertical: 10 }} onPress={() => {
                void onClickGrantLocationPermission()
              }} mode={'outlined'}>{t('profile.grant_location_permission', 'Grant location permission')}</Button>}
            {locationPermissionStatus === 'denied' &&
              <Text
                style={{ margin: 15 }}>{t('profile.location_permission_denied_try_manual', 'Location permissions denied. Open your device settings to manually grant this permission.')}</Text>}
            {locationPermissionStatus === 'unsupported' &&
              <Text
                style={{ margin: 15 }}>{t('profile.location_permission_not_supported', 'Location not supported by your device.')}</Text>}
            {locationPermissionStatus === null &&
              <Text
                style={{ margin: 15 }}>{t('profile.location_permission_unknown', 'Location permissions unknown.')}</Text>}

            <Title style={{ paddingTop: 30 }}><Ionicons name={'language-outline'} size={22}
                                                      color={theme.colors.secondary}/> {t('profile.language_title', 'Language')}
            </Title>
            <View>
              <RadioButton.Group onValueChange={changeLanguage} value={i18n.language}>
                <View>
                  <RadioButton.Item value="en" label={t('do_not_translate.lng_en', 'English')} position={'leading'}
                                    labelStyle={{ textAlign: 'left' }}/>
                </View>
                <View>
                  <RadioButton.Item value="nl" label={t('do_not_translate.lng_nl', 'Nederlands')} position={'leading'}
                                    labelStyle={{ textAlign: 'left' }}/>
                </View>
              </RadioButton.Group>
            </View>

            <Title style={{ paddingTop: 30 }}><Ionicons name={'person-outline'} size={22}
                                                      color={theme.colors.secondary}/> {t('profile.account_title', 'Account')}
            </Title>
            <Text style={{ margin: 15 }}>{authUser?.email}</Text>
            <Button style={{ marginVertical: 10 }} onPress={onLogout} mode={'outlined'}
                    icon={'logout'}>{t('common.logout', 'Logout')}</Button>
          </View>
        </ScrollView>
        <Snackbar style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
                  visible={toastMessage !== null}
                  onDismiss={() => setToastMessage(null)}
                  action={{ label: 'OK', onPress: () => setToastMessage(null) }}>{toastMessage}</Snackbar>
      </>
    )}/>
  </ProfileStack.Navigator>
}
