import React, { FC, useState } from 'react'
import { createNativeStackNavigator, NativeStackNavigationOptions } from '@react-navigation/native-stack'
import { Keyboard, Platform, ScrollView, View } from 'react-native'
import { Button, Modal, Title } from 'react-native-paper'
import { DialogScanAndManual, OnlyManualDialog } from '../../barcode/dialog'
import { debugSimulateScan } from '../../../app/config'
import { DetailScreen } from './DetailScreen'
import { DetailBooksScreen as OnLoanDetailScreen } from './../all/DetailScreen'
import { MyBooksListScreen } from './MyBooksScreen'
import { Book } from '../book'
import { useTranslation } from 'react-i18next'

const Stack = createNativeStackNavigator()

export const MyBooksTab: FC = () => {
  const [dirty, setDirty] = useState<boolean>(false)
  const [bookDeleted, setBookDeleted] = useState<Book | null>(null)
  const [addBookModalVisible, setAddBookModalVisible] = useState(false)
  const { t } = useTranslation()

  const addBook = (): void => {
    setAddBookModalVisible(true)
  }

  const onBookCreated = (): void => {
    setDirty(true)
    hideAddBookModal()
  }

  const onDismiss = (): void => {
    hideAddBookModal()
  }

  const hideAddBookModal = (): void => {
    setAddBookModalVisible(false)
    Keyboard.dismiss()
  }

  const screenOptions = ({ route }): NativeStackNavigationOptions => {
    const options: NativeStackNavigationOptions = {}

    if (route.name === 'My books') {
      options.headerRight = () => <Button onPress={addBook} icon={'plus'} compact={true} mode={'text'}>{t('common.add', 'Add')}</Button>
    }

    return options
  }

  const onBookDeleteFromDetailScreen = (book: Book): void => {
    setBookDeleted(book)
    setDirty(true)
  }

  const myBooksListScreen = (): JSX.Element => <MyBooksListScreen dirty={dirty} setDirty={setDirty} addBook={addBook} bookDeleted={bookDeleted} setBookDeleted={setBookDeleted} />

  const detailBookScreen = (): JSX.Element => <DetailScreen onUpdateFromDetailScreen={() => setDirty(true)} onBookDeleteFromDetailScreen={onBookDeleteFromDetailScreen}/>

  const onLoanDetailBookScreen = (): JSX.Element => <OnLoanDetailScreen onUpdateFromDetailScreen={() => setDirty(true)} />

  return (<>
      <Stack.Navigator screenOptions={screenOptions}>
        <Stack.Screen name={'My books'} children={myBooksListScreen} options={{ title: t('tabs.my_books_title', 'My Books') }}/>
        <Stack.Screen name={'Detail'} children={detailBookScreen} />
        <Stack.Screen name={'On Loan Detail'} children={onLoanDetailBookScreen} />
      </Stack.Navigator>
      <Modal visible={addBookModalVisible} contentContainerStyle={{ backgroundColor: 'white', padding: 20, margin: 20, flex: 1 }}
             onDismiss={hideAddBookModal} ><ScrollView><CreateBookModalContents onCreated={onBookCreated} onDismiss={onDismiss}></CreateBookModalContents></ScrollView></Modal>
    </>
  )
}

interface CreateBookModalContentsProps {
  onCreated: () => void
  onDismiss: () => void
}

const CreateBookModalContents: FC<CreateBookModalContentsProps> = ({ onCreated, onDismiss }) => {
  const { t } = useTranslation()

  const trCreateBook = t('my_books.add_book_to_your_collection', 'Add book to your collection')

  const showOnlyManualDialog = Platform.OS === 'web' && !debugSimulateScan

  return (
    <>
      <View>
        <Title>{trCreateBook}</Title>
        {showOnlyManualDialog && <OnlyManualDialog onDismiss={onDismiss} onCreated={onCreated}></OnlyManualDialog>}
        {!showOnlyManualDialog && <DialogScanAndManual onDismiss={onDismiss} onCreated={onCreated}></DialogScanAndManual>}
      </View>
    </>
  )
}
