import React, { FC, useEffect, useState } from 'react'
import { View } from 'react-native'
import { Button, TextInput } from 'react-native-paper'
import { PickupSlot } from '../../client/data-contracts'
import { useTranslation } from 'react-i18next'

interface Slot {
  date: string
  from: string
  until: string
}

export interface PickupSlotsInputProps {
  onChangeValue: (pickupSlots: PickupSlot[]) => void
}

export const PickupSlotsInput: FC<PickupSlotsInputProps> = ({ onChangeValue }) => {
  const { t } = useTranslation()

  const now = new Date()

  const zeroPad = (num, places): string => String(num).padStart(places, '0')

  const date = `${zeroPad(now.getFullYear(), 4)}-${zeroPad(now.getMonth() + 1, 2)}-${zeroPad(now.getDate(), 2)}`
  const from = `${zeroPad(now.getHours(), 2)}:${zeroPad(now.getMinutes(), 2)}`
  const until = `${zeroPad(now.getHours(), 2)}:${zeroPad(now.getMinutes(), 2)}`
  const [slots, setSlots] = useState<Slot[]>([{ date, from, until }])

  useEffect(() => {
    onChangeValue(toPickupSlots())
  }, [slots])

  const toPickupSlots = (): PickupSlot[] => slots.map((slot) => {
    const from = `${slot.date}T${slot.from}:00Z`
    const until = `${slot.date}T${slot.until}:00Z`
    return { from, until }
  })

  const addSlot = (): void => {
    let newSlot: Slot
    if (slots.length > 0) {
      const lastSlot = slots[slots.length - 1]
      newSlot = { ...lastSlot }
    } else {
      newSlot = { date, from, until }
    }
    setSlots([...slots, newSlot])
  }

  const deleteSlot = (index): void => {
    const newSlots = [...slots]
    newSlots.splice(index, 1)
    setSlots(newSlots)
  }

  const updateSlotDate = (index, newDate): void => {
    const slot = slots[index]
    const newSlot = { ...slot }
    newSlot.date = newDate
    const newSlots = [...slots]
    newSlots[index] = newSlot
    setSlots(newSlots)
  }

  const updateSlotFrom = (index, newFromTime): void => {
    const slot = slots[index]
    const newSlot = { ...slot }
    newSlot.from = newFromTime
    const newSlots = [...slots]
    newSlots[index] = newSlot
    setSlots(newSlots)
  }

  const updateSlotUntil = (index, newUntilTime): void => {
    const slot = slots[index]
    const newSlot = { ...slot }
    newSlot.until = newUntilTime
    const newSlots = [...slots]
    newSlots[index] = newSlot
    setSlots(newSlots)
  }

  const isDateValid = (v: string): boolean => {
    const re = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
    return re.test(v)
  }

  const isTimeValid = (v: string): boolean => {
    const re = /^[0-9]{2}:[0-9]{2}$/
    return re.test(v)
  }

  return <>
    <View>
      {slots.map((slot, index) => <View key={index} style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <TextInput dense={true} style={{ flex: 2, marginRight: 10 }} value={slot.date}
                     onChangeText={(text) => updateSlotDate(index, text)} mode={'outlined'}
                     error={!isDateValid(slot.date)}></TextInput>
          <TextInput dense={true} style={{ flex: 1, marginRight: 10 }} value={slot.from}
                     onChangeText={(text) => updateSlotFrom(index, text)} mode={'outlined'}
                     error={!isTimeValid(slot.from)}></TextInput>
          <TextInput dense={true} style={{ flex: 1 }} value={slot.until} onChangeText={(text) => updateSlotUntil(index, text)}
                     mode={'outlined'} error={!isTimeValid(slot.until)}></TextInput>
        </View>
        <Button onPress={() => deleteSlot(index)}>{t('common.delete', 'Delete')}</Button>
      </View>)}
    </View>
    <Button onPress={addSlot} icon={'plus'} style={{ marginTop: 5 }}>{t('pickup_slots.add_time_window', 'Add Time Window')}</Button>
  </>
}
