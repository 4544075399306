/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ErrorResponse, LocationsResponse, PickupLocation, PickupLocationId, SuccessResponse } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Pickup<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetPickupLocations
   * @request GET:/pickup/locations
   */
  getPickupLocations = (params: RequestParams = {}) =>
    this.request<LocationsResponse, ErrorResponse>({
      path: `/pickup/locations`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name CreatePickupLocation
   * @request POST:/pickup/locations
   */
  createPickupLocation = (Body: PickupLocation, params: RequestParams = {}) =>
    this.request<PickupLocationId, ErrorResponse | void>({
      path: `/pickup/locations`,
      method: "POST",
      body: Body,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name DeletePickupLocation
   * @request POST:/pickup/locations/{id}/delete
   */
  deletePickupLocation = (id: string, params: RequestParams = {}) =>
    this.request<SuccessResponse, ErrorResponse | void>({
      path: `/pickup/locations/${id}/delete`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name UpdatePickupLocation
   * @request POST:/pickup/locations/{id}/update
   */
  updatePickupLocation = (id: string, Body: PickupLocation, params: RequestParams = {}) =>
    this.request<SuccessResponse, ErrorResponse | void>({
      path: `/pickup/locations/${id}/update`,
      method: "POST",
      body: Body,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name UpdateSingletonPickupLocation
   * @request POST:/pickup/locations/update-singleton
   */
  updateSingletonPickupLocation = (Body: PickupLocation, params: RequestParams = {}) =>
    this.request<SuccessResponse, ErrorResponse | void>({
      path: `/pickup/locations/update-singleton`,
      method: "POST",
      body: Body,
      format: "json",
      ...params,
    });
}
