import {
  BookWithOutgoingRequests,
  canReserveBook,
  getActiveOutgoingRequest,
  waitingForBorrowerAction,
  waitingForOwnerAction
} from '../book'
import React, { FC, memo, useContext } from 'react'
import Ionicons from '@expo/vector-icons/Ionicons'
import { Image, Text, View } from 'react-native'
import { List } from 'react-native-paper'
import { ApiAuthContext } from '../../../app/api_auth_context'
import { useNavigation } from '@react-navigation/native'
import { getDistanceLowResolutionKm, isDistanceKnownAndRelevant } from '../../location/location'
import { CustomTheme, useCustomTheme } from '../../../app/themes'
import { useTranslation } from 'react-i18next'

export interface BookListItemProps {
  book: BookWithOutgoingRequests
  imageDataUrl: string
}

export const BookListItem: FC<BookListItemProps> = ({ book, imageDataUrl }) => {
  const activeRequest = getActiveOutgoingRequest(book)
  const navigation = useNavigation()
  const theme = useCustomTheme()

  let activeRequestBubble: JSX.Element = <></>

  if (activeRequest != null) {
    if (waitingForOwnerAction(activeRequest)) {
      activeRequestBubble = <Ionicons name={'ellipsis-horizontal-outline'}/>
    } else if (waitingForBorrowerAction(activeRequest)) {
      activeRequestBubble = <Ionicons name={'ellipse'} color={'red'}/>
    }
  }

  const navigateDetailScreen = (): void => {
    // @ts-expect-error
    navigation.navigate('Detail', { title: book.name, book })
  }

  return <List.Item
    left={() => left(imageDataUrl)}
    title={() => title(book, activeRequestBubble)}
    description={() => description(book, theme)}
    onPress={navigateDetailScreen}
    right={() => right(book, theme)}/>
}

const left = (imageDataUrl): JSX.Element => {
  return <View style={{ marginLeft: 15, justifyContent: 'flex-start' }}>
    <View style={{ width: 36, height: 36 }} >
      <Image
        source={{ uri: imageDataUrl }}
        resizeMode={'contain'}
        style={{ width: undefined, height: undefined, flex: 1 }}/>
    </View>
  </View>
}

const rightContent = (item: BookWithOutgoingRequests): JSX.Element => {
  const { t } = useTranslation()
  const activeRequest = getActiveOutgoingRequest(item)
  const apiAuthContext = useContext(ApiAuthContext)

  if (item.is_on_loan_to_me) {
    return <Text style={{ color: 'navy' }}>{t('all_books.on_loan_to_you', 'On loan to you')}</Text>
  } else if (canReserveBook(item, apiAuthContext.authUser?.id ?? '')) {
    if (activeRequest != null) {
      if (activeRequest.status === 'pending' || activeRequest.status === 'accepted') {
        return <Text style={{ color: 'green' }}>{t('all_books.pending', 'Pending…')}</Text>
      }
      if (activeRequest.status === 'has-available-pickup-slots') {
        return <Text style={{ color: 'green' }}>{t('all_books.select_pickup_time', 'Select pickup time')}</Text>
      }
      if (activeRequest.status === 'has-chosen-pickup-slot') {
        return <Text style={{ color: 'green' }}>{t('all_books.you_can_pickup_the_book', 'You can pickup the book')}</Text>
      }
    }
    // return <Text style={{ color: 'green' }}>Available</Text>
    return <></>
  } else if (item.owner_id === apiAuthContext.authUser?.id) {
    return <Text style={{ color: 'gray' }}>{t('all_books.owner', 'Owner')}</Text>
  } else {
    return <Text style={{ color: 'gray' }}>{t('all_books.unavailable', 'Unavailable')}</Text>
  }
}

const rightDistance = (item: BookWithOutgoingRequests, theme: CustomTheme): JSX.Element => {
  return <Text style={{ color: theme.colors.secondary }}><Ionicons name={'location-outline'}
                                                                   size={16}/> {getDistanceLowResolutionKm(item)}</Text>
}

const right = (item: BookWithOutgoingRequests, theme: CustomTheme): JSX.Element => {
  return <View style={{ marginLeft: 15, justifyContent: 'flex-start' }}>
    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>{rightContent(item)}</View>
    {isDistanceKnownAndRelevant(item) && <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>{rightDistance(item, theme)}</View>}
  </View>
}

const title = (item: BookWithOutgoingRequests, activeRequestBubble): JSX.Element => {
  return <Text>{item.name} {activeRequestBubble}</Text>
}

const description = (item: BookWithOutgoingRequests, theme: CustomTheme): JSX.Element => {
  const { t } = useTranslation()

  let authors = ''
  if (item.authors.length > 0) {
    authors = item.authors.join(', ').trim()
  }
  if (authors === '') {
    authors = t('all_books.name_of_unknown_author', 'Unknown')
  }

  let publishDate = ''
  if (item.publish_date != null && item.publish_date.length > 0) {
    publishDate = ` (${item.publish_date})`
  }

  return <Text style={{ color: theme.colors.secondary }}>{authors}{publishDate}</Text>
}

export const MemoBookListItem = memo(BookListItem)
