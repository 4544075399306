/* eslint-disable @typescript-eslint/naming-convention */
import { BookWithOutgoingRequests } from '../books/book'

/* eslint-disable @typescript-eslint/naming-convention */
export const getDistanceLowResolutionKm = (item: BookWithOutgoingRequests): string => {
  if (item.is_on_loan_to_me) {
    return '0 km'
  } else if (item.is_distance_known) {
    if (item.distance < 1000) {
      // TODO translate
      return 'within 1 km'
    } else {
      return Math.round(item.distance / 1000).toString() + ' km'
    }
  } else {
    return '?'
  }
}

export const isDistanceKnownAndRelevant = (item: BookWithOutgoingRequests): boolean => {
  if (item.is_on_loan_to_me) {
    return false
  } else if (item.is_distance_known) {
    if (item.distance < 999) {
      return true
    } else {
      return true
    }
  } else {
    return false
  }
}
