import { Image, View } from 'react-native'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { ApiAuthContext } from '../../app/api_auth_context'
import { CoverCacheContext, CoverNotFound } from './CoverCache'
import { Book } from '../books/book'
import { thumbHashToDataURL } from 'thumbhash'
import 'core-js/modules/web.atob'
import { PlaceholderBookCover } from '../books/all/placeholder'

export interface BigCoverImageProps {
  book: Book
  squareSize: number
}

const getThumbHash = (book): string => {
  return thumbHashToDataURL(Uint8Array.from(atob(book.cover_thumb_hash), c => c.charCodeAt(0)))
}

export const BigCoverImage: FC<BigCoverImageProps> = ({ book, squareSize }) => {
  const { apiClient } = useContext(ApiAuthContext)
  const [imageDataUrl, setImageDataUrl] = useState<string | undefined>(undefined)
  const { cache, addCache, setNotFound } = useContext(CoverCacheContext)
  const cacheKey = `big-${book.id}`
  const fromCache: string | undefined = useMemo(() => cache[cacheKey], [cache, book])
  const thumbHash: string = useMemo(() => getThumbHash(book), [])

  useEffect(() => {
    if (fromCache !== undefined) {
      return
    }

    void apiClient?.rawCoverFetch(book.id)
      .then((response) => {
        if (response.status === 200) {
          void response.blob()
            .then(blobToBase64)
            .then((base64data) => {
              setImageDataUrl(base64data as string)
              addCache(cacheKey, base64data as string)
            })
            .catch((error) => {
              setNotFound(cacheKey)
              throw error
            })
        } else {
          setNotFound(cacheKey)
        }
      })
      .catch((error) => {
        setNotFound(cacheKey)
        console.error(error)
      })
  }, [])

  let width = book.has_cover ? book.cover_width : 28
  let height = book.has_cover ? book.cover_height : 36
  if (height > width) {
    width = squareSize * width / height
    height = squareSize
  } else {
    height = squareSize * height / width
    width = squareSize
  }

  let uri = ''
  if (book.has_cover) {
    if (fromCache === CoverNotFound) {
      uri = PlaceholderBookCover
    } else {
      uri = fromCache ?? imageDataUrl
    }
  } else {
    uri = PlaceholderBookCover
  }

  return (
        <View style={{ width: squareSize, height: squareSize }}>
            <View style={{ position: 'absolute', top: 0, left: 0, zIndex: 1, alignItems: 'center', justifyContent: 'center', width: squareSize, height: squareSize }}>
                <Image
                    source={{ uri: thumbHash }}
                    resizeMode={'stretch'}
                    style={{ width, height }}/>
            </View>
            <View style={{ position: 'absolute', top: 0, left: 0, zIndex: 2, alignItems: 'center', justifyContent: 'center', width: squareSize, height: squareSize }}>
                <Image
                    source={{ uri }}
                    resizeMode={'stretch'}
                    style={{ width, height }}/>
            </View>
        </View>
  )
}

const blobToBase64 = async (blob): Promise<string | ArrayBuffer | null> => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return await new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}
