import { BookRequestInfoResponse, IncomingRequest, OutgoingRequest, PickupSlot } from '../../client/data-contracts'

export interface Book {
  id: string
  name: string
  authors: string[]
  isbn: string
  publish_date: string
  owner_id: string
  is_rentable: boolean
  is_on_loan: boolean
  loaned_at: string
  is_on_loan_to_me: boolean
  is_owned_by_me: boolean
  // @format date-time
  canonical_created_at: string
  // @format date-time
  user_owned_created_at: string
  has_cover: boolean
  cover_width: number
  cover_height: number
  // @format base64
  cover_thumb_hash: string
  is_distance_known: boolean
  distance: number
}

export interface MyBookWithIncomingRequests extends Book {
  requests: IncomingRequest[]
  has_active_request: boolean
  active_request: IncomingRequest | null
}

export interface BookWithOutgoingRequests extends Book {
  requests: OutgoingRequest[]
  has_active_request: boolean
  active_request: OutgoingRequest | null
}

export type BookRequestStatus =
  'pending'
  | 'cancelled'
  | 'rejected'
  | 'accepted'
  | 'has-available-pickup-slots'
  | 'no-feasible-pickup-slots'
  | 'has-chosen-pickup-slot'
  | 'picked-up'
  | 'has-available-return-slots'
  | 'no-feasible-return-slots'
  | 'has-chosen-return-slot'
  | 'returned'

export interface BookRequest {
  id: string
  bookId: string
  requestedByUserId: string
  status: BookRequestStatus
  // TODO make these names independent from whatever the API returns
  available_pickup_slots: PickupSlot[] | null
  // TODO make these names independent from whatever the API returns
  chosen_pickup_slot: PickupSlot | null
}

export const isBookRequestStatusFinal = (s: BookRequestStatus): boolean => {
  switch (s) {
    case 'cancelled':
    case 'rejected':
    case 'returned':
      return true

    default:
      return false
  }
}

export const canReserveBook = (b: Book, myUserId: string): boolean => {
  return b.is_rentable && !b.is_on_loan && b.owner_id !== myUserId
}

export const waitingForOwnerAction = (r: BookRequest | IncomingRequest | OutgoingRequest): boolean => {
  return [
    'pending',
    'accepted',
    'no-feasible-pickup-slots',
    'has-chosen-pickup-slot',
    'no-feasible-return-slots',
    'has-chosen-return-slot'
  ].includes(r.status)
}

export const waitingForBorrowerAction = (r: BookRequest | IncomingRequest | OutgoingRequest): boolean => {
  return [
    'has-available-pickup-slots',
    'has-available-return-slots'
  ].includes(r.status)
}

export const getActiveIncomingRequest = (book: MyBookWithIncomingRequests): IncomingRequest | undefined => {
  if (typeof book.requests === 'undefined') {
    console.log('requestless book', book)
    return
  }
  for (const request of book.requests) {
    if (!isBookRequestStatusFinal(request.status)) {
      return request
    }
  }
}

export const hasActiveIncomingRequest = (book: MyBookWithIncomingRequests): boolean => {
  return getActiveIncomingRequest(book) !== undefined
}

export const getActiveOutgoingRequest = (book: BookWithOutgoingRequests): OutgoingRequest | undefined => {
  if (typeof book.requests === 'undefined') {
    console.log('requestless book', book)
    return
  }
  for (const request of book.requests) {
    if (!isBookRequestStatusFinal(request.status)) {
      return request
    }
  }
}

export const hasActiveOutgoingRequest = (book: BookWithOutgoingRequests): boolean => {
  return getActiveOutgoingRequest(book) !== undefined
}
export const getBookOwnerNameFromBookRequestInfo = (bookRequestInfo: BookRequestInfoResponse): string => {
  return bookRequestInfo?.bookOwner?.email ?? ''
}

export const getRequestedByUserNameFromBookRequestInfo = (bookRequestInfo: BookRequestInfoResponse): string => {
  return bookRequestInfo?.requestedByUser?.email ?? ''
}
