import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useTranslation } from 'react-i18next'
import { FlatList, Platform, RefreshControl, Text } from 'react-native'
import { Appbar, Button, List, Menu } from 'react-native-paper'
import { BookRequestNotificationData, NotificationWithId } from '../../client/data-contracts'
import { ApiAuthContext } from '../../app/api_auth_context'
import { TFunction } from 'i18next'
import { useNavigation } from '@react-navigation/native'
import { DetailBooksScreen } from '../books/all/DetailScreen'
import { DetailScreen } from '../books/mybooks/DetailScreen'
import { LocationContext, usualSuspectSrid } from '../location/LocationContext'
import { GetSingleBookOptions } from '../api/client'
import { LocalNotification, NotificationStore } from './notifications'
import { isThisYear, isToday, shortMonthName, parseRFC3339DateTime } from '../../app/dates'
import { useCustomTheme } from '../../app/themes'

const Stack = createNativeStackNavigator()

interface CustomMenuProps {
  onClear: () => void
}

const CustomMenu: FC<CustomMenuProps> = ({ onClear }) => {
  const [visible, setVisible] = React.useState(false)
  const openMenu = (): void => setVisible(true)
  const closeMenu = (): void => setVisible(false)
  const { t } = useTranslation()

  return (
    <Menu
      visible={visible}
      onDismiss={closeMenu}
      anchor={
        <Appbar.Action
          icon="dots-vertical"
          onPress={openMenu}
        />
      }>
      <Menu.Item
        onPress={() => {
          onClear()
          closeMenu()
        }}
        title={t('notifications.clear_all', 'Clear All')}
      />
    </Menu>
  )
}

export const NotificationsTab: FC = () => {
  const { t } = useTranslation()
  const [clearRequested, setClearRequested] = React.useState(false)

  const onClear = (): void => {
    setClearRequested(true)
  }

  return (
    <Stack.Navigator>
      <Stack.Screen name={'Notifications List'}
                    children={() => <NotificationsScreen clearRequested={clearRequested}
                                                         setClearRequested={setClearRequested}/>}
                    options={{
                      title: t('tabs.notifications_title'),
                      headerRight: () => <CustomMenu onClear={onClear}/>
                    }}/>
      <Stack.Screen name={'Notifications My Book Detail'}
                    children={() => <DetailScreen/>}/>
      <Stack.Screen name={'Notifications Their Book Detail'}
                    children={() => <DetailBooksScreen onUpdateFromDetailScreen={() => {
                    }}/>}/>
    </Stack.Navigator>
  )
}

interface NotificationsScreenProps {
  clearRequested: boolean
  setClearRequested: (v: boolean) => void
}

const NotificationsScreen: FC<NotificationsScreenProps> = ({ clearRequested, setClearRequested }) => {
  const { apiClient } = useContext(ApiAuthContext)
  const { t } = useTranslation()
  const [refreshing, setRefreshing] = useState<boolean>(true)
  const store = useMemo(() => new NotificationStore(), [])
  const [localNotifications, setLocalNotifications] = useState<LocalNotification[]>([])

  const updateNotifications = async (): Promise<void> => {
    setRefreshing(true)
    return await store.getNotifications()
      .then(value => {
        setLocalNotifications(value)
      })
      .then(() => apiClient?.getAllNotificationsForMe())
      .then(async value => {
        const localNotifications = store.toLocalNotifications(value ?? [])
        return await store.addNotifications(localNotifications)
      })
      .then(value => {
        setLocalNotifications(value)
      })
      .finally(() => setRefreshing(false))
  }

  useEffect(() => {
    void updateNotifications()
  }, [])

  const refresh = (): void => {
    void updateNotifications()
  }

  const clear = (): void => {
    setLocalNotifications([])
  }

  useEffect(() => {
    if (clearRequested) {
      clear()
      setClearRequested(false)
    }
  }, [clearRequested, setClearRequested])

  const listData = useMemo((): Array<LocalNotification | string> => {
    if (!refreshing && localNotifications.length === 0) {
      return ['empty']
    }
    return [...localNotifications]
  }, [localNotifications, refreshing])

  return <>
    <FlatList data={listData}
              refreshControl={<RefreshControl refreshing={refreshing} onRefresh={refresh}/>}
              renderItem={({ item }) =>
                <MemoNotificationListItem item={item} />}>
    </FlatList>
    {Platform.OS === 'web' &&
      <Button onPress={refresh} mode={'outlined'} style={{ margin: 16 }}
              disabled={refreshing} loading={refreshing}>{t('common.reload', 'Reload')}</Button>
    }
  </>
}

const NotificationListItem: FC<{ item: LocalNotification | string }> = ({ item }) => {
  const { t } = useTranslation()

  if (item === 'empty') {
    return <List.Item key={1} title={t('notifications.you_are_all_caught_up')}></List.Item>
  }

  const notification = (item as LocalNotification).notification

  if (notification.data_type === 'BookRequestNotificationData') {
    return <MemoBookRequestNotificationListItem item={notification}/>
  } else {
    return <List.Item key={notification.id} title={notification.type} right={() => <Text></Text>}></List.Item>
  }
}

const MemoNotificationListItem = memo(NotificationListItem)

const BookRequestNotificationListItem: FC<{ item: NotificationWithId }> = ({ item }) => {
  const { t } = useTranslation()
  const { authUser, apiClient } = useContext(ApiAuthContext)
  const navigation = useNavigation()
  const data = useMemo(() => item.data as BookRequestNotificationData, [item])
  const description = useMemo(() => buildBookRequestDescription(t, item), [item])
  const { latitude, longitude, isLocationKnown } = useContext(LocationContext)
  const theme = useCustomTheme()

  const onPress = useCallback((): void => {
    const ownedByMe = data.user_owned_book.owner_id.localeCompare(authUser?.id ?? '') === 0
    if (ownedByMe) {
      void apiClient?.getMyBookWithIncomingRequests(data.user_owned_book.id)
        .then(book => {
          if (book === null) return
          // @ts-expect-error
          navigation.navigate('Notifications My Book Detail', { book })
        })
    } else {
      const options: GetSingleBookOptions = {}
      if (isLocationKnown) {
        options.lat = latitude
        options.lon = longitude
        options.srid = usualSuspectSrid
      }
      void apiClient?.getSingleBookWithOutgoingRequests(data.user_owned_book.id, options)
        .then(book => {
          if (book === null) return
          // @ts-expect-error
          navigation.navigate('Notifications Their Book Detail', { book })
        })
    }
  }, [item, authUser])

  const createdAt = parseRFC3339DateTime(item.created_at)
  let right = ''
  if (isToday(createdAt)) {
    right = `${createdAt.getHours()}:${createdAt.getMinutes()}`
  } else if (isThisYear(createdAt)) {
    right = `${createdAt.getDate()} ${shortMonthName(createdAt.getMonth())}`
  } else {
    right = `${shortMonthName(createdAt.getMonth())} ${createdAt.getFullYear()}`
  }

  return <List.Item key={item.id} title={description} onPress={onPress}
                    right={() => <Text style={{ color: theme.colors.secondary }}>{right}</Text>}></List.Item>
}

const MemoBookRequestNotificationListItem = memo(BookRequestNotificationListItem)

const buildBookRequestDescription = (t: TFunction<'translation', undefined>, item: NotificationWithId): string => {
  switch (item.type) {
    case 'book_requested':
      return t('notifications.descriptions.book_requested', 'One of your books has been requested')
    case 'book_request_accepted':
      return t('notifications.descriptions.book_request_accepted', 'Your book request has been accepted')
    case 'book_request_rejected':
      return t('notifications.descriptions.book_request_rejected', 'Your book request has been rejected')
    case 'book_request_cancelled_by_requester':
      return t('notifications.descriptions.book_request_cancelled_by_requester', 'The requester has cancelled their book request')
    case 'book_request_cancelled_by_owner':
      return t('notifications.descriptions.book_request_cancelled_by_owner', 'The owner has canceled your book request')
    case 'book_request_has_available_pickup_slots':
      return t('notifications.descriptions.book_request_has_available_pickup_slots', 'Please let the owner know when you pick up the book')
    case 'book_request_owner_removed_pickup_slots':
      return t('notifications.descriptions.book_request_owner_removed_pickup_slots', 'Please let the owner know when you pick up the book')
    case 'book_request_no_feasible_pickup_slots':
      return t('notifications.descriptions.book_request_no_feasible_pickup_slots', 'Borrower cannot make any of the pickup slots')
    case 'book_request_has_chosen_pickup_slot':
      return t('notifications.descriptions.book_request_has_chosen_pickup_slot', 'Borrower has selected a pickup slot')
    case 'book_request_has_available_return_slots':
      return t('notifications.descriptions.book_request_has_available_return_slots', 'Owner wants you to return the book, please select time')
    case 'book_request_owner_removed_return_slots':
      return t('notifications.descriptions.book_request_owner_removed_return_slots', 'Owner wants you to return the book, please select time')
    case 'book_request_no_feasible_return_slots':
      return t('notifications.descriptions.book_request_no_feasible_return_slots', 'Borrower cannot make any of the return slots')
    case 'book_request_has_chosen_return_slot':
      return t('notifications.descriptions.book_request_has_chosen_return_slot', 'Borrower has selected a return slot')
    case 'book_request_picked_up': // TODO does this exist?
    case 'book_request_returned': // TODO does this exist?
    default:
      return item.type
  }
}
