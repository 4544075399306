import {
  Book,
  BookRequest,
  BookWithOutgoingRequests,
  getActiveIncomingRequest,
  getActiveOutgoingRequest,
  isBookRequestStatusFinal,
  MyBookWithIncomingRequests
} from '../books/book'
import * as config from '../../app/config'
import { RequestBook } from '../../client/RequestBook'
import { GetOutgoingBookRequests } from '../../client/GetOutgoingBookRequests'
import {
  BookRequestInfoResponse,
  CreateAccountResponse,
  ErrorResponse,
  IncomingRequest,
  JsonChoosePickupSlotRequest,
  JsonChooseReturnSlotRequest,
  JsonMarkBookRequestPickedUpRequest,
  JsonSetAvailablePickupSlotsRequest,
  JsonSetAvailableReturnSlotsRequest,
  LoginResponse,
  NotificationWithId,
  OutgoingRequest,
  PickupLocation,
  PickupSlot,
  SearchIsbnResponse
} from '../../client/data-contracts'
import { CancelBookRequest } from '../../client/CancelBookRequest'
import { GetIncomingBookRequests } from '../../client/GetIncomingBookRequests'
import { AcceptBookRequest } from '../../client/AcceptBookRequest'
import { RejectBookRequest } from '../../client/RejectBookRequest'
import { Pickup } from '../../client/Pickup'
import { BookRequests } from '../../client/BookRequests'
import { Auth } from '../../client/Auth'
import { AuthUser } from '../../app/api_auth_context'
import { MarkBookRequestPickedUp } from '../../client/MarkBookRequestPickedUp'
import { HttpResponse, RequestParams } from '../../client/http-client'
import 'core-js/modules/web.btoa'
import { Search } from '../../client/Search'
import { Platform } from 'react-native'
import { MyBooks } from '../../client/MyBooks'
import { Notifications } from '../../client/Notifications'
import { ActiveOutgoingRequestBooks } from '../../client/ActiveOutgoingRequestBooks'

export interface GetAllBooksOptions {
  lat?: number
  lon?: number
  srid?: number
  page?: number
  limit?: number
  query?: string
  must_be_rentable?: boolean
  must_not_be_on_loan?: boolean
  must_not_be_yours?: boolean
  sort?: 'added' | 'authors' | 'distance' | 'title'
  dir?: 'asc' | 'desc'
}

export interface GetSingleBookOptions {
  lat?: number
  lon?: number
  srid?: number
}

export interface ApiClient {
  setAccessToken: (token: string | undefined) => void
  setOnUnauthorized: (onUnauthorized: () => void) => void
  getAllBooks: (options?: GetAllBooksOptions) => Promise<{ books: Book[] }>
  getAllBooksWithOutgoingRequests: (options?: GetAllBooksOptions) => Promise<{ books: BookWithOutgoingRequests[], is_last_page_or_beyond: boolean }>
  getSingleBookWithOutgoingRequests: (bookId: string, options?: GetSingleBookOptions) => Promise<BookWithOutgoingRequests | null>
  getMyBooks: () => Promise<{ books: Book[] }>
  getMyBooksWithIncomingRequests: () => Promise<MyBookWithIncomingRequests[]>
  getMyBookWithIncomingRequests: (bookId: string) => Promise<MyBookWithIncomingRequests | null>
  getOnLoanBooksWithOutgoingRequests: () => Promise<BookWithOutgoingRequests[]>
  getActiveOutgoingRequestBooks: () => Promise<BookWithOutgoingRequests[]>
  createBook: (name: string, isbn: string, authors: string[], publishDate: string) => Promise<string>
  createBookByIsbn: (isbn: string) => Promise<string>
  deleteBook: (bookId: string) => Promise<void>
  checkToken: (token: string) => Promise<boolean>
  login: (email: string, password: string) => Promise<LoginResponse | null>
  createAccount: (email: string, password: string, captchaType: string, captchaCode: string) => Promise<CreateAccountResponse>
  getAuthUser: () => Promise<AuthUser | null>
  setIsRentable: (book: Book, isRentable: boolean) => Promise<void>
  requestBook: (book: Book) => Promise<void>
  getActiveOutgoingRequestForBook: (book: Book) => Promise<OutgoingRequest | null>
  getAllOutgoingRequests: () => Promise<OutgoingRequest[]>
  getAllIncomingRequests: () => Promise<IncomingRequest[]>
  getIncomingRequestsForBook: (book: Book) => Promise<IncomingRequest[]>
  bookRequestInfo: (bookRequestId: string) => Promise<BookRequestInfoResponse>
  cancelBookRequest: (bookRequest: BookRequest) => Promise<void>
  acceptBookRequest: (bookRequest: BookRequest) => Promise<void>
  rejectBookRequest: (bookRequest: BookRequest) => Promise<void>
  getSingletonPickupLocation: () => Promise<PickupLocation>
  updateSingletonPickupLocation: (pickupLocation: PickupLocation) => Promise<void>
  setAvailablePickupSlots: (bookRequestId: string, pickupSlots: PickupSlot[]) => Promise<void>
  removeAvailablePickupSlots: (bookRequestId: string) => Promise<void>
  noFeasiblePickupSlots: (bookRequestId: string) => Promise<void>
  choosePickupSlot: (bookRequestId: string, pickupSlot: PickupSlot) => Promise<void>
  setAvailableReturnSlots: (bookRequestId: string, returnSlots: PickupSlot[]) => Promise<void>
  removeAvailableReturnSlots: (bookRequestId: string) => Promise<void>
  noFeasibleReturnSlots: (bookRequestId: string) => Promise<void>
  chooseReturnSlot: (bookRequestId: string, returnSlot: PickupSlot) => Promise<void>
  getSingletonPickupLocationForBookRequest: (bookRequestId: string) => Promise<PickupLocation>
  markBookRequestPickedUp: (bookRequestId: string) => Promise<void>
  markBookRequestReturned: (bookRequestId: string) => Promise<void>
  searchIsbn: (isbn: string) => Promise<SearchIsbnResponse | null>
  rawCoverFetch: (bookId: string) => Promise<Response>
  rawCoverThumbnailFetch: (bookId: string) => Promise<Response>
  getAllNotificationsForMe: () => Promise<NotificationWithId[]>
}

export class RealApiClient implements ApiClient {
  private accessToken: string | undefined = undefined
  private refreshToken: string | undefined = undefined
  private baseUrl: string
  private onUnauthorized?: () => void

  constructor (baseUrl: string) {
    this.baseUrl = baseUrl
  }

  setBaseUrl (baseUrl: string): void {
    this.baseUrl = baseUrl
  }

  setAccessToken (accessToken: string | undefined): void {
    this.accessToken = accessToken
  }

  setRefreshToken (refreshToken: string | undefined): void {
    this.refreshToken = refreshToken
  }

  setOnUnauthorized (fn: () => void): void {
    this.onUnauthorized = fn
  }

  private getHeaders (): { [index: string]: string } {
    const headers = {
      Authorization: `Bearer ${this.accessToken ?? ''}`
    }

    if (config.enableHeaderXClientVersion) {
      headers['X-Client-Version'] = `${config.version}-${config.versionCode}-${Platform.OS}`
    }

    return headers
  }

  private async fetch (url: string, options?: RequestInit | undefined): Promise<Response> {
    options = this.addAuthorizationHeader(options)
    const r = await fetch(url, options)
    if (r.status === 401) {
      // TODO implement refresh token or callback logic
      this.onUnauthorized?.()
    }
    return r
  }

  private async apiClientFetch (fn, params: RequestParams, ...args): Promise<any> {
    params = this.addAuthorizationHeader(params)
    const r = await fn(...args, params).catch(apiResponseCatch)
    if (r.status === 401) {
      // TODO implement refresh token or callback logic
      this.onUnauthorized?.()
    }
    return r
  }

  private addAuthorizationHeader (options: RequestInit | undefined): RequestInit {
    if (typeof options === 'undefined') {
      options = {}
    }

    if (typeof options.headers === 'undefined') {
      options.headers = {}
    }

    options.headers = { ...this.getHeaders(), ...options.headers }

    return options
  }

  async getAllBooks (options?: GetAllBooksOptions): Promise<{ books: Book[], is_last_page_or_beyond: boolean }> {
    let querystring = ''
    if (options !== undefined) {
      const init: Record<string, any> = {
        lat: options.lat?.toString() ?? '',
        lon: options.lon?.toString() ?? '',
        srid: options.srid?.toString() ?? ''
      }
      if (options.page !== undefined) {
        init.page = options.page
        init.limit = options.limit
      }
      if (options.query !== undefined) {
        init.query = options.query
      }
      if (options.must_not_be_yours === true) {
        init.must_not_be_yours = 'true'
      }
      if (options.must_be_rentable === true) {
        init.must_be_rentable = 'true'
      }
      if (options.must_not_be_on_loan === true) {
        init.must_not_be_on_loan = 'true'
      }
      if (options.sort !== undefined) {
        init.sort = options.sort
        init.dir = options.dir
      }
      const params = new URLSearchParams(init)
      querystring = '?' + params.toString()
    }

    const r = await this.fetch(`${this.baseUrl}/all-books${querystring}`)

    if (r.status === 200) {
      return await r.json()
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async getAllBooksWithOutgoingRequests (options?: GetAllBooksOptions): Promise<{ books: BookWithOutgoingRequests[], is_last_page_or_beyond: boolean }> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { books, is_last_page_or_beyond } = await this.getAllBooks(options)
    const allOutgoingRequests = await this.getAllOutgoingRequests()

    const booksWithRequests: BookWithOutgoingRequests[] = []
    for (let i = 0; i < books.length; i++) {
      const book = books[i]
      const requests = allOutgoingRequests.filter(r => r.book_id === book.id)
      const bookWithRequests: BookWithOutgoingRequests = {
        ...book,
        requests,
        has_active_request: false,
        active_request: null
      }
      bookWithRequests.active_request = getActiveOutgoingRequest(bookWithRequests) ?? null
      bookWithRequests.has_active_request = bookWithRequests.active_request !== null
      booksWithRequests.push(bookWithRequests)
    }
    return { books: booksWithRequests, is_last_page_or_beyond }
  }

  async getSingleBookWithOutgoingRequests (bookId: string, options?: GetSingleBookOptions): Promise<BookWithOutgoingRequests | null> {
    let querystring = ''
    if (options !== undefined) {
      querystring = '?' + new URLSearchParams({
        lat: options.lat?.toString() ?? '',
        lon: options.lon?.toString() ?? '',
        srid: options.srid?.toString() ?? ''
      }).toString()
    }

    const r = await this.fetch(`${this.baseUrl}/all-books/${bookId}${querystring}`)
    if (r.status === 200) {
      return await r.json().then(res => {
        const book = res.book
        book.requests = book.outgoing_requests ?? []
        book.outgoing_requests = undefined
        book.active_request = getActiveOutgoingRequest(book) ?? null
        book.has_active_request = book.active_request !== null
        return book
      })
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else if (r.status === 404) {
      throw new Error('Not Found')
    } else {
      throw new Error('Unknown')
    }
  }

  async getMyBooks (): Promise<{ books: Book[] }> {
    const r = await this.fetch(`${this.baseUrl}/my-books`)
    if (r.status === 200) {
      return await r.json()
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async getMyBooksWithIncomingRequests (): Promise<MyBookWithIncomingRequests[]> {
    // TODO optimize
    const books = (await this.getMyBooks()).books
    const allIncomingRequests = await this.getAllIncomingRequests()

    const myBooksWithRequests: MyBookWithIncomingRequests[] = []
    for (let i = 0; i < books.length; i++) {
      const book = books[i]
      // TODO optimize
      if (book.is_owned_by_me) {
        const requests = allIncomingRequests.filter(r => r.book_id === book.id)
        const myBookWithRequests: MyBookWithIncomingRequests = {
          ...book,
          requests,
          has_active_request: false,
          active_request: null
        }
        myBookWithRequests.active_request = getActiveIncomingRequest(myBookWithRequests) ?? null
        myBookWithRequests.has_active_request = myBookWithRequests.active_request !== null
        myBooksWithRequests.push(myBookWithRequests)
      }
    }
    return myBooksWithRequests
  }

  async getMyBookWithIncomingRequests (bookId: string): Promise<MyBookWithIncomingRequests | null> {
    const r = await this.fetch(`${this.baseUrl}/my-books/${bookId}`)
    if (r.status === 200) {
      return await r.json().then(res => {
        const book = res.book
        book.requests = book.incoming_requests ?? []
        book.incoming_requests = undefined
        book.active_request = getActiveIncomingRequest(book)
        book.has_active_request = book.active_request !== null
        return book
      })
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else if (r.status === 404) {
      throw new Error('Not Found')
    } else {
      throw new Error('Unknown')
    }
  }

  async getOnLoanBooksWithOutgoingRequests (): Promise<BookWithOutgoingRequests[]> {
    // TODO optimize
    const books = (await this.getMyBooks()).books
    const allOutgoingRequests = await this.getAllOutgoingRequests()

    const onLoanBooksWithRequests: BookWithOutgoingRequests[] = []
    for (let i = 0; i < books.length; i++) {
      const book = books[i]
      // TODO optimize
      if (book.is_on_loan_to_me) {
        const requests = allOutgoingRequests.filter(r => r.book_id === book.id)
        const onLoanBookWithRequests: BookWithOutgoingRequests = {
          ...book,
          requests,
          has_active_request: false,
          active_request: null
        }
        onLoanBookWithRequests.active_request = getActiveOutgoingRequest(onLoanBookWithRequests) ?? null
        onLoanBookWithRequests.has_active_request = onLoanBookWithRequests.active_request !== null
        onLoanBooksWithRequests.push(onLoanBookWithRequests)
      }
    }
    return onLoanBooksWithRequests
  }

  async getActiveOutgoingRequestBooks (): Promise<BookWithOutgoingRequests[]> {
    const api = new ActiveOutgoingRequestBooks({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.getActiveOutgoingRequestBooks>> =
      await this.apiClientFetch(api.getActiveOutgoingRequestBooks, {})

    if (r.status === 200) {
      const books = r.data.books as BookWithOutgoingRequests[]
      for (let i = 0; i < books.length; i++) {
        const book = books[i]
        book.requests = (book as any).outgoing_requests
        book.active_request = getActiveOutgoingRequest(book) ?? null
        book.has_active_request = book.active_request !== null
      }
      return books
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async createBook (name: string, isbn: string, authors: string[], publishDate: string): Promise<string> {
    const headers = { 'Content-Type': 'application/json' }
    const method = 'POST'
    const body = JSON.stringify({ book: { name, isbn, authors, publish_date: publishDate } })
    const r = await this.fetch(`${this.baseUrl}/create-and-add-to-collection`, { headers, method, body })
    if (r.status === 200 || r.status === 201) {
      return await r.json().then(value => {
        if (isErrorResponseData(value)) {
          throw new Error(value.error)
        } else {
          return value.book.id
        }
      })
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async createBookByIsbn (isbn: string): Promise<string> {
    const headers = { 'Content-Type': 'application/json' }
    const method = 'POST'
    const body = JSON.stringify({ isbn })
    const r = await this.fetch(`${this.baseUrl}/create-and-add-to-collection-by-isbn`, { headers, method, body })
    if (r.status === 200 || r.status === 201) {
      return await r.json().then(value => {
        if (isErrorResponseData(value)) {
          throw new Error(value.error)
        } else {
          return value.book.id
        }
      })
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async deleteBook (bookId: string): Promise<void> {
    const api = new MyBooks({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.deleteMyBook>> =
      await this.apiClientFetch(api.deleteMyBook, {}, bookId)

    if (isErrorResponseData(r.error)) {
      throw new Error(r.error.error)
    }

    if (r.ok) {
      // return
    } else if (r.status === 400) {
      throw new Error('Bad Request')
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else if (r.status === 404) {
      throw new Error('Not Found')
    } else {
      throw new Error('Unknown')
    }
  }

  async checkToken (token: string): Promise<boolean> {
    const headers = { Authorization: 'email ' + token }
    const r = await fetch(`${this.baseUrl}/auth/check-email-token`, { headers })
    if (r.status === 200) {
      return true
    } else if (r.status === 401) {
      return false
    } else {
      throw new Error('Unknown')
    }
  }

  async login (email: string, password: string): Promise<LoginResponse | null> {
    const headers = { 'Content-Type': 'application/json' }
    const method = 'POST'
    const body = JSON.stringify({ email, password })
    const r = await this.fetch(`${this.baseUrl}/auth/login`, { headers, method, body })

    if (r.status === 200) {
      return await r.json()
    } else if (r.status === 401) {
      return null
    } else {
      throw new Error('Unknown')
    }
  }

  async createAccount (email: string, password: string, captchaType: string, captchaCode: string): Promise<CreateAccountResponse> {
    const api = new Auth({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.createAccount>> =
      await this.apiClientFetch(api.createAccount, {}, {
        email,
        password,
        captcha_type: captchaType,
        captcha_code: captchaCode
      })

    if (r.status === 201) {
      return r.data
    } else if (isErrorResponseData(r.error)) {
      throw new Error(r.error.error)
    } else {
      throw new Error('Unknown')
    }
  }

  async getAuthUser (): Promise<AuthUser | null> {
    const api = new Auth({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.getAuthUser>> =
            await this.apiClientFetch(api.getAuthUser, {})

    if (r.ok) {
      return r.data as AuthUser
    } else if (r.status === 401) {
      return null
    } else {
      throw new Error('Unknown')
    }
  }

  async setIsRentable (book: Book, isRentable: boolean): Promise<void> {
    const headers = { 'Content-Type': 'application/json' }
    const method = 'PATCH'
    const body = JSON.stringify({ book: { is_rentable: isRentable } })
    const r = await this.fetch(`${this.baseUrl}/my-books/${book.id}/set-is-rentable`, { headers, method, body })
    if (r.status === 200) {
      // return
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async requestBook (book: Book): Promise<void> {
    const api = new RequestBook({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.requestBook>> =
            await this.apiClientFetch(api.requestBook, {}, { book: { id: book.id } })

    if (r.ok) {
      if (r.data.success === true) {
        // return
      } else {
        throw new Error((r as HttpResponse<ErrorResponse>).data.error)
      }
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async getActiveOutgoingRequestForBook (book: Book): Promise<OutgoingRequest | null> {
    const api = new GetOutgoingBookRequests({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.getOutgoingRequests>> =
            await this.apiClientFetch(api.getOutgoingRequests, {})

    if (r.ok) {
      if (r.data.requests !== null && r.data.requests !== undefined) {
        for (let i = 0; i < r.data.requests.length; i++) {
          const request = r.data.requests[i]
          if (request.book_id === book.id && !isBookRequestStatusFinal(request.status)) {
            return request
          }
        }
      }
      return null
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async getIncomingRequestsForBook (book: Book): Promise<IncomingRequest[]> {
    const api = new GetIncomingBookRequests({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.getIncomingRequests>> =
            await this.apiClientFetch(api.getIncomingRequests, {})

    if (r.ok) {
      const requestsForBook: IncomingRequest[] = []
      if (r.data.requests != null) {
        for (let i = 0; i < r.data.requests.length; i++) {
          const request = r.data.requests[i]
          if (request.book_id === book.id) {
            requestsForBook.push(request)
          }
        }
      }
      requestsForBook.sort((a, b) => a.id?.localeCompare(b.id ?? '') ?? 0)
      return requestsForBook
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async getAllIncomingRequests (): Promise<IncomingRequest[]> {
    const api = new GetIncomingBookRequests({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.getIncomingRequests>> =
            await this.apiClientFetch(api.getIncomingRequests, {})

    if (r.ok) {
      let requestsForBook: IncomingRequest[] = []
      if (r.data.requests != null) {
        requestsForBook = r.data.requests
      }
      requestsForBook.sort((a, b) => a.id?.localeCompare(b.id ?? '') ?? 0)
      return requestsForBook
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async getAllOutgoingRequests (): Promise<OutgoingRequest[]> {
    const api = new GetOutgoingBookRequests({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.getOutgoingRequests>> =
            await this.apiClientFetch(api.getOutgoingRequests, {})

    if (r.ok) {
      let requestsForBook: OutgoingRequest[] = []
      if (r.data.requests != null) {
        requestsForBook = r.data.requests
      }
      requestsForBook.sort((a, b) => a.id?.localeCompare(b.id ?? '') ?? 0)
      return requestsForBook
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async bookRequestInfo (bookRequestId: string): Promise<BookRequestInfoResponse> {
    const api = new BookRequests({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.bookRequestInfo>> =
            await this.apiClientFetch(api.bookRequestInfo, {}, bookRequestId)

    if (r.ok) {
      if (isErrorResponse(r)) {
        throw new Error(r.data.error)
      }
      return r.data
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async cancelBookRequest (bookRequest: BookRequest): Promise<void> {
    const api = new CancelBookRequest({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.cancelBookRequest>> =
            await this.apiClientFetch(api.cancelBookRequest, {}, { book_request: { id: bookRequest.id } })

    if (r.ok) {
      if (r.data.success === true) {
        // return
      } else {
        throw new Error((r as HttpResponse<ErrorResponse>).data.error)
      }
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async acceptBookRequest (bookRequest: BookRequest): Promise<void> {
    const api = new AcceptBookRequest({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.acceptBookRequest>> =
            await this.apiClientFetch(api.acceptBookRequest, {}, { book_request: { id: bookRequest.id } })

    if (r.ok) {
      if (r.data.success === true) {
        // return
      } else {
        throw new Error((r as HttpResponse<ErrorResponse>).data.error)
      }
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async rejectBookRequest (bookRequest: BookRequest): Promise<void> {
    const api = new RejectBookRequest({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.rejectBookRequest>> =
            await this.apiClientFetch(api.rejectBookRequest, {}, { book_request: { id: bookRequest.id } })

    if (r.ok) {
      if (r.data.success === true) {
        // return
      } else {
        throw new Error((r as HttpResponse<ErrorResponse>).data.error)
      }
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async getSingletonPickupLocation (): Promise<PickupLocation> {
    const api = new Pickup({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.getPickupLocations>> =
            await this.apiClientFetch(api.getPickupLocations, {})

    if (r.ok) {
      if (r.data.locations != null && r.data.locations.length >= 1) {
        return r.data.locations[0]
      } else {
        return {
          address: '',
          country_alpha_3: 'NLD',
          notes: '',
          postal_code: '',
          locality: ''
        }
      }
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async updateSingletonPickupLocation (pickupLocation: PickupLocation): Promise<void> {
    const headers = { 'Content-Type': 'application/json' }
    const method = 'POST'
    const body = JSON.stringify(pickupLocation)

    const r = await this.fetch(`${this.baseUrl}/pickup/locations/update-singleton`, {
      headers,
      method,
      body
    }).catch(reason => {
      throw reason
    })

    if (r.ok) {
      void r.json().then(value => {
        if (value.success === true) {
          // return
        } else {
          throw new Error(value.error)
        }
      })
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async setAvailablePickupSlots (bookRequestId: string, pickupSlots: PickupSlot[]): Promise<void> {
    const body: JsonSetAvailablePickupSlotsRequest = { pickup_slots: [] }
    for (let i = 0; i < pickupSlots.length; i++) {
      const slot = pickupSlots[i]
      body.pickup_slots?.push([slot.from, slot.until])
    }

    const api = new BookRequests({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.setAvailablePickupSlots>> =
            await this.apiClientFetch(api.setAvailablePickupSlots, {}, bookRequestId, body)

    if (r.ok) {
      if (r.data.success === true) {
        // return
      } else {
        throw new Error((r as HttpResponse<ErrorResponse>).data.error)
      }
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async removeAvailablePickupSlots (bookRequestId: string): Promise<void> {
    const api = new BookRequests({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.removeAvailablePickupSlots>> =
      await this.apiClientFetch(api.removeAvailablePickupSlots, {}, bookRequestId)

    if (r.ok) {
      if (r.data.success === true) {
        // return
      } else {
        throw new Error((r as HttpResponse<ErrorResponse>).data.error)
      }
    } else if (r.status === 400) {
      throw new Error('Bad Request')
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else if (r.status === 403) {
      throw new Error('Forbidden')
    } else if (r.status === 404) {
      throw new Error('Not Found')
    } else if (r.status === 409) {
      throw new Error('Conflict')
    } else {
      throw new Error('Unknown')
    }
  }

  async noFeasiblePickupSlots (bookRequestId: string): Promise<void> {
    const api = new BookRequests({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.noFeasiblePickupSlots>> =
            await this.apiClientFetch(api.noFeasiblePickupSlots, {}, bookRequestId)

    if (r.ok) {
      if (r.data.success === true) {
        // return
      } else {
        throw new Error((r as HttpResponse<ErrorResponse>).data.error)
      }
    } else if (r.status === 400) {
      throw new Error('Bad Request')
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else if (r.status === 403) {
      throw new Error('Forbidden')
    } else if (r.status === 404) {
      throw new Error('Not Found')
    } else if (r.status === 409) {
      throw new Error('Conflict')
    } else {
      throw new Error('Unknown')
    }
  }

  async choosePickupSlot (bookRequestId: string, slot: PickupSlot): Promise<void> {
    const body: JsonChoosePickupSlotRequest = { pickup_slot: [slot.from, slot.until] }

    const api = new BookRequests({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.choosePickupSlot>> =
            await this.apiClientFetch(api.choosePickupSlot, {}, bookRequestId, body)

    if (r.ok) {
      if (r.data.success === true) {
        // return
      } else {
        throw new Error((r as HttpResponse<ErrorResponse>).data.error)
      }
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async setAvailableReturnSlots (bookRequestId: string, returnSlots: PickupSlot[]): Promise<void> {
    const body: JsonSetAvailableReturnSlotsRequest = { return_slots: [] }
    for (let i = 0; i < returnSlots.length; i++) {
      const slot = returnSlots[i]
      body.return_slots?.push([slot.from, slot.until])
    }

    const api = new BookRequests({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.setAvailableReturnSlots>> =
            await this.apiClientFetch(api.setAvailableReturnSlots, {}, bookRequestId, body)

    if (r.ok) {
      if (r.data.success === true) {
        // return
      } else {
        throw new Error((r as HttpResponse<ErrorResponse>).data.error)
      }
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async removeAvailableReturnSlots (bookRequestId: string): Promise<void> {
    const api = new BookRequests({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.removeAvailableReturnSlots>> =
      await this.apiClientFetch(api.removeAvailableReturnSlots, {}, bookRequestId)

    if (r.ok) {
      if (r.data.success === true) {
        // return
      } else {
        throw new Error((r as HttpResponse<ErrorResponse>).data.error)
      }
    } else if (r.status === 400) {
      throw new Error('Bad Request')
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else if (r.status === 403) {
      throw new Error('Forbidden')
    } else if (r.status === 404) {
      throw new Error('Not Found')
    } else if (r.status === 409) {
      throw new Error('Conflict')
    } else {
      throw new Error('Unknown')
    }
  }

  async noFeasibleReturnSlots (bookRequestId: string): Promise<void> {
    const api = new BookRequests({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.noFeasibleReturnSlots>> =
            await this.apiClientFetch(api.noFeasibleReturnSlots, {}, bookRequestId)

    if (r.ok) {
      if (r.data.success === true) {
        // return
      } else {
        throw new Error((r as HttpResponse<ErrorResponse>).data.error)
      }
    } else if (r.status === 400) {
      throw new Error('Bad Request')
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else if (r.status === 403) {
      throw new Error('Forbidden')
    } else if (r.status === 404) {
      throw new Error('Not Found')
    } else if (r.status === 409) {
      throw new Error('Conflict')
    } else {
      throw new Error('Unknown')
    }
  }

  async chooseReturnSlot (bookRequestId: string, slot: PickupSlot): Promise<void> {
    const body: JsonChooseReturnSlotRequest = { return_slot: [slot.from, slot.until] }

    const api = new BookRequests({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.chooseReturnSlot>> =
            await this.apiClientFetch(api.chooseReturnSlot, {}, bookRequestId, body)

    if (r.ok) {
      if (r.data.success === true) {
        // return
      } else {
        throw new Error((r as HttpResponse<ErrorResponse>).data.error)
      }
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async markBookRequestPickedUp (bookRequestId: string): Promise<void> {
    const body: JsonMarkBookRequestPickedUpRequest = { book_request: { id: bookRequestId } }

    const api = new MarkBookRequestPickedUp({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.markBookRequestPickedUp>> =
            await this.apiClientFetch(api.markBookRequestPickedUp, {}, body)

    if (r.ok) {
      if (r.data.success === true) {
        // return
      } else {
        throw new Error((r as HttpResponse<ErrorResponse>).data.error)
      }
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async markBookRequestReturned (bookRequestId: string): Promise<void> {
    const api = new BookRequests({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.markBookRequestReturned>> =
            await this.apiClientFetch(api.markBookRequestReturned, {}, bookRequestId)

    if (r.ok) {
      if (r.data.success === true) {
        // return
      } else {
        throw new Error((r as HttpResponse<ErrorResponse>).data.error)
      }
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else if (r.status === 404) {
      throw new Error('Not Found')
    } else {
      throw new Error('Unknown')
    }
  }

  async getSingletonPickupLocationForBookRequest (bookRequestId: string): Promise<PickupLocation> {
    const api = new BookRequests({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.getSingletonPickupLocationForBookRequest>> =
            await this.apiClientFetch(api.getSingletonPickupLocationForBookRequest, {}, bookRequestId)

    if (r.ok) {
      return r.data
    } else if (r.status === 400) {
      throw new Error('Cannot reveal location yet')
    } else if (r.status === 404) {
      throw new Error('Not found')
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async searchIsbn (isbn: string): Promise<SearchIsbnResponse | null> {
    const cleanIsbn = isbn.replace(/[^0-9]/g, '')

    const api = new Search({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.searchIsbn>> =
            await this.apiClientFetch(api.searchIsbn, {}, cleanIsbn)

    if (r.ok) {
      // @ts-expect-error
      if (r.data.success === false) {
        throw new Error((r as HttpResponse<ErrorResponse>).data.error)
      } else {
        return r.data
      }
    } else if (r.status === 404) {
      throw new Error('Not found')
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }

  async rawCoverFetch (bookId: string): Promise<Response> {
    return await this.fetch(`${this.baseUrl}/all-books/${bookId}/cover`)
  }

  async rawCoverThumbnailFetch (bookId: string): Promise<Response> {
    return await this.fetch(`${this.baseUrl}/all-books/${bookId}/cover/thumb`)
  }

  async getAllNotificationsForMe (): Promise<NotificationWithId[]> {
    const api = new Notifications({ baseUrl: this.baseUrl })
    const r: Awaited<ReturnType<typeof api.getAllNotificationsForMe>> =
      await this.apiClientFetch(api.getAllNotificationsForMe, {})

    if (r.ok) {
      return r.data.notifications
    } else if (r.status === 401) {
      throw new Error('Unauthorized')
    } else {
      throw new Error('Unknown')
    }
  }
}

const apiResponseCatch = (response): void => {
  if (response.status !== undefined) {
    return response
  } else {
    throw response
  }
}

const isErrorResponse = (r: any): r is HttpResponse<ErrorResponse> => {
  return isErrorResponseData(r?.data)
}

const isErrorResponseData = (data: any): data is ErrorResponse => {
  return (data?.success === false && data?.error !== undefined)
}
