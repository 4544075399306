import { NotificationWithId } from '../../client/data-contracts'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { parseRFC3339DateTime } from '../../app/dates'
import { Callback, CallbackWithResult } from '@react-native-async-storage/async-storage/src/types'

interface StorageBackend {
  getItem: (key: string, callback?: CallbackWithResult<string>) => Promise<string | null>
  setItem: (key: string, value: string, callback?: Callback) => Promise<void>
}

export interface LocalNotification {
  notification: NotificationWithId
  markedAsRead: boolean
}

const isLocalNotification = (value: any): value is LocalNotification => {
  return (
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    Object.prototype.hasOwnProperty.call(value, 'notification') &&
    Object.prototype.hasOwnProperty.call(value, 'markedAsRead')
  )
}

const SANE_LIMIT_TO_AVOID_TOO_MUCH_MEMORY_CONSUMPTION = 99

export class NotificationStore {
  private readonly backend: StorageBackend

  constructor (backend?: StorageBackend) {
    this.backend = backend ?? AsyncStorage
  }

  toLocalNotification (notificationWithId: NotificationWithId): LocalNotification {
    return {
      notification: notificationWithId,
      markedAsRead: false
    }
  }

  toLocalNotifications (notificationsWithId: NotificationWithId[]): LocalNotification[] {
    return notificationsWithId.map(this.toLocalNotification)
  }

  protected sortKey (n: LocalNotification): string {
    return parseRFC3339DateTime(n.notification.created_at).getTime().toString() + ' ' + n.notification.id
  }

  async getNotifications (): Promise<LocalNotification[]> {
    return await AsyncStorage.getItem('@notifications').then(this.deserializeNotifications).then(this.cleanup)
  }

  async addNotifications (newNotifications: LocalNotification[]): Promise<LocalNotification[]> {
    return await AsyncStorage.getItem('@notifications')
      .then(this.deserializeNotifications)
      .then(this.cleanup)
      .then(async notifications => {
        const actualNew = newNotifications.filter(n => {
          return undefined === notifications.find(n2 => n2.notification.id.localeCompare(n.notification.id) === 0)
        })
        notifications = [...notifications, ...actualNew]
        notifications = notifications.sort((a, b) => {
          return this.sortKey(b).localeCompare(this.sortKey(a))
        })
        // await AsyncStorage.setItem('@notifications', this.serializeNotifications(notifications))
        return notifications
      })
      .then(this.cleanup)
      .then(this.limit)
      .then(this.updateNotifications)
  }

  updateNotifications = async (notifications: LocalNotification[]): Promise<LocalNotification[]> => {
    return await AsyncStorage.setItem('@notifications', this.serializeNotifications(notifications)).then(() => notifications)
  }

  protected cleanup (dirty: LocalNotification[]): LocalNotification[] {
    return dirty.filter(isLocalNotification)
  }

  protected limit (notifications: LocalNotification[]): LocalNotification[] {
    notifications.splice(SANE_LIMIT_TO_AVOID_TOO_MUCH_MEMORY_CONSUMPTION, Infinity)
    return notifications
  }

  protected serializeNotifications = (notifications: LocalNotification[]): string => {
    return JSON.stringify(notifications)
  }

  protected deserializeNotifications = (serialized: any): LocalNotification[] => {
    try {
      const deserialized = JSON.parse(serialized)
      if (!Array.isArray(deserialized)) {
        return []
      }
      return deserialized
    } catch {
      return []
    }
  }
}
