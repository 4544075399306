/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BookRequestInfoResponse,
  ErrorResponse,
  JsonChoosePickupSlotRequest,
  JsonChooseReturnSlotRequest,
  JsonSetAvailablePickupSlotsRequest,
  JsonSetAvailableReturnSlotsRequest,
  PickupLocation,
  SuccessResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class BookRequests<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name BookRequestInfo
   * @request GET:/book_requests/{id}
   */
  bookRequestInfo = (id: string, params: RequestParams = {}) =>
    this.request<BookRequestInfoResponse, ErrorResponse>({
      path: `/book_requests/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name ChoosePickupSlot
   * @request POST:/book_requests/{id}/choose-pickup-slot
   */
  choosePickupSlot = (id: string, Body: JsonChoosePickupSlotRequest, params: RequestParams = {}) =>
    this.request<SuccessResponse, ErrorResponse>({
      path: `/book_requests/${id}/choose-pickup-slot`,
      method: "POST",
      body: Body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name ChooseReturnSlot
   * @request POST:/book_requests/{id}/choose-return-slot
   */
  chooseReturnSlot = (id: string, Body: JsonChooseReturnSlotRequest, params: RequestParams = {}) =>
    this.request<SuccessResponse, ErrorResponse>({
      path: `/book_requests/${id}/choose-return-slot`,
      method: "POST",
      body: Body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name GetSingletonPickupLocationForBookRequest
   * @request GET:/book_requests/{id}/get-pickup-location
   */
  getSingletonPickupLocationForBookRequest = (id: string, params: RequestParams = {}) =>
    this.request<PickupLocation, ErrorResponse | void>({
      path: `/book_requests/${id}/get-pickup-location`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name MarkBookRequestReturned
   * @request POST:/book_requests/{id}/mark-returned
   */
  markBookRequestReturned = (id: string, params: RequestParams = {}) =>
    this.request<SuccessResponse, void>({
      path: `/book_requests/${id}/mark-returned`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name NoFeasiblePickupSlots
   * @request POST:/book_requests/{id}/no-feasible-pickup-slots
   */
  noFeasiblePickupSlots = (id: string, params: RequestParams = {}) =>
    this.request<SuccessResponse, ErrorResponse>({
      path: `/book_requests/${id}/no-feasible-pickup-slots`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name NoFeasibleReturnSlots
   * @request POST:/book_requests/{id}/no-feasible-return-slots
   */
  noFeasibleReturnSlots = (id: string, params: RequestParams = {}) =>
    this.request<SuccessResponse, ErrorResponse>({
      path: `/book_requests/${id}/no-feasible-return-slots`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name RemoveAvailablePickupSlots
   * @request POST:/book_requests/{id}/remove-available-pickup-slots
   */
  removeAvailablePickupSlots = (id: string, params: RequestParams = {}) =>
    this.request<SuccessResponse, ErrorResponse>({
      path: `/book_requests/${id}/remove-available-pickup-slots`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name RemoveAvailableReturnSlots
   * @request POST:/book_requests/{id}/remove-available-return-slots
   */
  removeAvailableReturnSlots = (id: string, params: RequestParams = {}) =>
    this.request<SuccessResponse, ErrorResponse>({
      path: `/book_requests/${id}/remove-available-return-slots`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name SetAvailablePickupSlots
   * @request POST:/book_requests/{id}/set-available-pickup-slots
   */
  setAvailablePickupSlots = (id: string, Body: JsonSetAvailablePickupSlotsRequest, params: RequestParams = {}) =>
    this.request<SuccessResponse, ErrorResponse | void>({
      path: `/book_requests/${id}/set-available-pickup-slots`,
      method: "POST",
      body: Body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name SetAvailableReturnSlots
   * @request POST:/book_requests/{id}/set-available-return-slots
   */
  setAvailableReturnSlots = (id: string, Body: JsonSetAvailableReturnSlotsRequest, params: RequestParams = {}) =>
    this.request<SuccessResponse, ErrorResponse | void>({
      path: `/book_requests/${id}/set-available-return-slots`,
      method: "POST",
      body: Body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
