import { FC, useContext, useEffect, useState } from 'react'
import { Pressable, Text, View } from 'react-native'
import { Button, TextInput, Title, useTheme } from 'react-native-paper'
import { debugLoginPass, debugLoginUser } from '../../app/config'
import { ApiAuthContext } from '../../app/api_auth_context'
import { useNavigation } from '@react-navigation/native'
import * as config from '../../app/config'
import { capitalizeFirstLetter } from '../../app/text'
import { useTranslation } from 'react-i18next'
import Ionicons from '@expo/vector-icons/Ionicons'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useCustomTheme } from '../../app/themes'

interface LoginBox2Props {
  onLoginSuccess: (accessToken: string) => void
}

export const LoginBox2: FC<LoginBox2Props> = ({ onLoginSuccess }) => {
  const { t, i18n } = useTranslation()
  const theme = useCustomTheme()
  const { apiClient } = useContext(ApiAuthContext)
  const [loggedIn, setLoggedIn] = useState(false)
  const [email, setEmail] = useState('')
  const [inputEmail, setInputEmail] = useState('')
  const [inputPassword, setInputPassword] = useState('')
  const [passwordHidden, setPasswordHidden] = useState(true)
  const [invalidTokenAttempt, setInvalidTokenAttempt] = useState(false)
  const [loginError, setLoginError] = useState<string | undefined>(undefined)
  const navigation = useNavigation()
  const trLogin = t('common.login', 'Log in')
  const trDistributedLibraryTitle = t('login.welcome_title', 'Welcome to\nDistributed Library!')
  const trWelcomeEmail = t('login.welcome_message', { email })
  const trCreateAccount = t('common.register', 'Register')

  const onLoginFormSubmit = (): void => {
    const cleanInputEmail = inputEmail.trim()
    setLoginError(undefined)
    setInvalidTokenAttempt(false)
    apiClient?.login(cleanInputEmail, inputPassword).then(loginResponse => {
      if (loginResponse !== null) {
        setLoggedIn(true)
        setEmail(cleanInputEmail)
        onLoginSuccess(loginResponse.access_token)
      } else {
        setInvalidTokenAttempt(true)
      }
    }).catch(reason => {
      if (reason instanceof Error) {
        setLoginError(capitalizeFirstLetter(reason.message))
      } else {
        setLoginError(capitalizeFirstLetter(reason.toString()))
      }
    })
  }

  const onClickCreateAccount = (): void => {
    // @ts-expect-error
    navigation.navigate('signup')
  }

  useEffect(() => {
    if (debugLoginUser !== undefined && debugLoginPass !== undefined) {
      setInputEmail(debugLoginUser)
      setInputPassword(debugLoginPass)
    }
  }, [])

  const toggleBetweenEnglishAndDutch = (): void => {
    const newLng = i18n.language === 'en' ? 'nl' : 'en'
    void i18n.changeLanguage(newLng)
    void AsyncStorage.setItem('@preferredLanguage', newLng)
      .catch(reason => {
        console.log('could not save preferred language to AsyncStorage', reason)
      })
  }

  if (loggedIn) {
    return <Text>
      {trWelcomeEmail}.
    </Text>
  } else {
    return <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Pressable style={{ position: 'absolute', top: 15, right: 15, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
                 onPress={toggleBetweenEnglishAndDutch}>
        <Ionicons name={'language-outline'} size={22} color={theme.colors.secondary}/>
        <Text style={{ color: theme.colors.secondary }}>
          {i18n.language === 'en' ? t('do_not_translate.lng_en') : t('do_not_translate.lng_nl')}
        </Text>
      </Pressable>
      <Title style={{ textAlign: 'center', marginBottom: 15 }}>{trDistributedLibraryTitle}</Title>
      <TextInput value={inputEmail} label={t('common.email', 'Email')} mode={'outlined'} style={{ width: '80%', maxWidth: 300 }}
                 onChangeText={setInputEmail} autoComplete={'username'} importantForAutofill={'yes'}
                 autoCapitalize={'none'}
                 keyboardType={'email-address'} textContentType={'username'}></TextInput>
      <TextInput style={{ marginTop: 10, width: '80%', maxWidth: 300 }} value={inputPassword} label={t('common.password', 'Password')}
                 mode={'outlined'} autoCapitalize={'none'}
                 onChangeText={setInputPassword} autoComplete={'password'} importantForAutofill={'yes'}
                 secureTextEntry={passwordHidden}
                 right={<TextInput.Icon icon={passwordHidden ? 'eye' : 'eye-off'} onPress={() => setPasswordHidden(!passwordHidden)}/>}
                 textContentType={'password'}></TextInput>
      <Button style={{ marginTop: 15, marginBottom: 15, width: '80%', maxWidth: 300 }} mode={'outlined'} onPress={e => onLoginFormSubmit()}>{trLogin}</Button>
      {config.featureToggleAllowRegistration && <Button style={{ margin: 15 }} onPress={e => onClickCreateAccount()}>{trCreateAccount}</Button>}
      {(invalidTokenAttempt) ? <Text>{t('login.invalid_credentials', 'Unknown email or incorrect password')}</Text> : <></>}
      {(loginError !== undefined) ? <Text>{t('login.unexpected_error', { error: loginError })}</Text> : <></>}
    </View>
  }
}
