import * as React from 'react'
import { View, TouchableOpacity, Text, Pressable } from 'react-native'
import { Checkbox } from 'react-native-paper'
import { FC } from 'react'

export interface CheckBoxProps {
  label: string
  status: 'checked' | 'unchecked' | 'indeterminate'
  onPress: () => void
}

const CheckBox: FC<CheckBoxProps> = ({ label, status, onPress }) => {
  return (
    <Pressable onPress={onPress}>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <Checkbox status={status}/>
        <Text style={{ flex: 1 }}>{label}</Text>
      </View>
    </Pressable>
  )
}

export default CheckBox
