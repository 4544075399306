import React, { FC, useState } from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { AllBooksScreen, AllBooksScreenFilter, getDefaultAllBooksScreenFilter } from './AllBooksScreen'
import { DetailBooksScreen } from './DetailScreen'
import { LocationPermissionStatus } from '../../../app/location'
import { useTranslation } from 'react-i18next'

const Stack = createNativeStackNavigator()

export interface AllBooksTabProps {
  onLocationPermissionChange: (val: LocationPermissionStatus) => void
}

export const AllBooksTab: FC<AllBooksTabProps> = ({ onLocationPermissionChange }) => {
  const { t } = useTranslation()
  const [persistentFilter, setPersistentFilter] = useState<AllBooksScreenFilter>(getDefaultAllBooksScreenFilter())
  const [dirty, setDirty] = useState<boolean>(false)

  const allBooksScreen = (props: any): React.ReactNode => (
    <AllBooksScreen filter={persistentFilter}
                    onUpdateFilter={setPersistentFilter}
                    dirty={dirty}
                    setDirty={setDirty}
                    onLocationPermissionChange={onLocationPermissionChange}/>
  )

  const detailBooksScreen = (props: any): React.ReactNode => (
    <DetailBooksScreen onUpdateFromDetailScreen={() => setDirty(true)}/>
  )

  return (
    <Stack.Navigator>
      <Stack.Screen name={'Distributed Library'} children={allBooksScreen} options={{ title: t('tabs.library_title') }}/>
      <Stack.Screen name={'Detail'} children={detailBooksScreen}/>
    </Stack.Navigator>

  )
}
