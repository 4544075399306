import { FC, memo, useCallback, useEffect, useState } from 'react'
import { ActivityIndicator, Linking, Platform, Text, View } from 'react-native'
import { Button, Provider as PaperProvider, Title } from 'react-native-paper'
import { AllBooksTab } from './features/books/all/AllBooksTab'
import { MyBooksTab } from './features/books/mybooks/MyBooksTab'
import { NavigationContainer } from '@react-navigation/native'
import Ionicons from '@expo/vector-icons/Ionicons'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { RealApiClient } from './features/api/client'
import { ApiAuthContext, AuthUser } from './app/api_auth_context'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { CombinedLightTheme } from './app/themes'
import * as config from './app/config'
import { ProfileScreen } from './features/profile/ProfileScreen'
import { CoverCacheContext, CoverNotFound } from './features/widgets/CoverCache'
import { defaultLocationContextValue, LocationContext, LocationContextValue } from './features/location/LocationContext'
import * as Location from 'expo-location'
import { AccountNavigator } from './features/login/AccountNavigator'
import { getCurrentLocation, getLocationPermissionStatus, LocationPermissionStatus } from './app/location'
import './i18n'
import { useTranslation } from 'react-i18next'
import { NotificationsTab } from './features/notifications/NotificationsTab'
import { IconProps } from '@expo/vector-icons/build/createIconSet'

const Tab = createBottomTabNavigator()

const apiClient = new RealApiClient(config.apiUrl)

const TabAllBooks = 'AllBooks'
const TabMyBooks = 'MyBooks'
const TabProfile = 'Profile'
const TabNotifications = 'Notifications'
const tabNames = [TabAllBooks, TabMyBooks, TabProfile, TabNotifications]
type TabName = typeof tabNames[number]
const isTabName = (value: any): value is TabName => (typeof value === 'string') && tabNames.includes(value)

type AppState =
  'start-state'
  | 'checking-manifest'
  | 'manifest-ok'
  | 'needs-update'
  | 'offline-or-server-error'
  | 'checking-auth'
  | 'ready'

const loadingScreen = (
  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}><ActivityIndicator size={'large'}/></View>
)

const openPlayStore = (): void => {
  void Linking.openURL('market://details?id=nl.hennekedevelopment.dislib').catch(() => {})
}

const updateScreen = (
  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', padding: 15 }}>
    <Title style={{ marginBottom: 15 }}>Update available</Title>
    <Text style={{ marginBottom: 20, textAlign: 'center' }}>Please update Distibuted Library to continue</Text>
    {Platform.OS === 'android' && <Button onPress={openPlayStore}>Open Play Store to update</Button>}
  </View>
)

const OfflineOrServerErrorScreen: FC<{ onRetry: () => void }> = memo(({ onRetry }) => {
  return (
  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', padding: 15 }}>
    <Title style={{ marginBottom: 15 }}>Cannot connect to server</Title>
    <Text style={{ marginBottom: 20, textAlign: 'center' }}>The app cannot connect to the server. Either the server is down, or we cannot access the internet.</Text>
    <Button onPress={onRetry}>Retry</Button>
  </View>
  )
})

const App: FC = () => {
  const [appState, setAppState] = useState<AppState>('start-state')
  const [loggedIn, setLoggedIn] = useState(false)
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [authUser, setAuthUser] = useState<AuthUser | null>(null)
  const [cache, setCache] = useState<Record<string, string>>({})
  const [lastOpenedTab, setLastOpenedTab] = useState<TabName>(TabMyBooks)
  const { t, i18n } = useTranslation()

  const addCache = useCallback((cacheKey: string, value: string): void => {
    setCache(cache => ({ ...cache, [cacheKey]: value }))
  }, [cache])

  const setNotFound = useCallback((cacheKey: string): void => {
    setCache(cache => ({ ...cache, [cacheKey]: CoverNotFound }))
  }, [cache])

  const coverCacheContextValue = {
    cache,
    addCache,
    setNotFound
  }

  const apiAuthContextValue = { loggedIn, accessToken, apiClient, authUser }

  const [locationContextValue, setLocationContextValue] = useState<LocationContextValue>({ ...defaultLocationContextValue })

  useEffect(() => {
    let ignore = false
    void (async () => {
      const status = await getLocationPermissionStatus()
      if (ignore) return
      setLocationContextValue({
        ...locationContextValue,
        locationPermissionStatus: status
      })
      if (status !== 'granted') return

      const location = await getCurrentLocation()
      if (ignore) return
      setLocationContextValue({
        ...locationContextValue,
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        isLocationKnown: true,
        locationPermissionStatus: status
      })
    })().catch(reason => {
      // ignore no location permissions
    })
    return (): void => { ignore = true }
  }, [])

  useEffect(() => {
    let ignore = false
    void AsyncStorage.getItem('@lastOpenedTab').then(retrievedLastOpenedTab => {
      if (ignore) return
      if (isTabName(retrievedLastOpenedTab)) {
        setLastOpenedTab(retrievedLastOpenedTab)
      }
    })
    return (): void => { ignore = true }
  }, [])

  useEffect(() => {
    void AsyncStorage.setItem('@lastOpenedTab', lastOpenedTab)
  }, [lastOpenedTab])

  useEffect(() => {
    let ignore = false

    if (appState !== 'start-state') {
      return
    }

    void AsyncStorage.getItem('@preferredLanguage')
      .then(lng => {
        if (ignore) return
        if (typeof lng === 'string') {
          void i18n.changeLanguage(lng)
        }
      })
      .catch(reason => {
        if (ignore) return
        console.log('could not load preferred language from AsyncStorage', reason)
      })

    return () => { ignore = false }
  }, [appState])

  useEffect(() => {
    if (appState === 'start-state') {
      setAppState('checking-manifest')
    }
  }, [appState])

  useEffect(() => {
    let ignore = false

    if (appState !== 'checking-manifest') {
      return
    }

    if (!config.useAndroidAppInfo) {
      setAppState('manifest-ok')
      return
    }

    // force reload of app info
    const url = `${config.androidAppInfoUrl}?${new Date().getTime()}`

    void fetch(url)
      .then(response => {
        if (ignore) return
        if (response.ok) {
          return response.json()
        } else {
          console.warn('Cannot parse app info')
          setAppState('offline-or-server-error')
        }
      })
      .then(json => {
        if (ignore) return
        if (json !== null) {
          if (json.minimal_supported_version_code > config.versionCode) {
            setAppState('needs-update')
            return
          }
          if (json.backend_url === undefined || json.backend_url === null) {
            console.warn('backend url not set')
            setAppState('offline-or-server-error')
            return
          }

          const apiUrl = json.backend_url
          apiClient.setBaseUrl(apiUrl)
          setAppState('manifest-ok')
        }
      })
      .catch((reason) => {
        if (ignore) return
        console.warn('Cannot connect to server due to error', reason, config.androidAppInfoUrl)
        setAppState('offline-or-server-error')
      })

    return (): void => { ignore = true }
  }, [appState])

  useEffect(() => {
    if (appState === 'manifest-ok') {
      setAppState('checking-auth')
    }
  }, [appState])

  useEffect(() => {
    let ignore = false

    if (appState !== 'checking-auth') {
      return
    }

    void AsyncStorage.getItem('@accessToken').then(retrievedAccessToken => {
      if (ignore) return
      if (retrievedAccessToken === null) {
        setAccessToken(null)
        setLoggedIn(false)
        setAuthUser(null)
      } else {
        setAccessToken(retrievedAccessToken)
        apiClient.setAccessToken(retrievedAccessToken)
        return apiClient.getAuthUser().then(user => {
          if (ignore) return
          if (user === null) {
            setLoggedIn(false)
            setAuthUser(null)
            setAccessToken(null)
          } else {
            setLoggedIn(true)
            setAuthUser(user)
          }
        })
      }
    }).finally(() => {
      if (ignore) return
      setAppState('ready')
    })

    return (): void => { ignore = true }
  }, [appState])

  const onRetry = (): void => {
    setAppState('start-state')
  }

  const onLoginSuccess = (accessToken: string): void => {
    setAccessToken(accessToken)
    apiClient.setAccessToken(accessToken)
    setLoggedIn(true)
    void AsyncStorage.setItem('@accessToken', accessToken)
    void apiClient.getAuthUser().then(value => {
      setAuthUser(value)
    })
  }

  const onLogout = (): void => {
    setAccessToken(null)
    apiClient.setAccessToken(undefined)
    setLoggedIn(false)
    setAuthUser(null)
    void AsyncStorage.removeItem('@accessToken')
  }

  const onLocationPermissionChange = (res: LocationPermissionStatus): void => {
    if (res === 'granted' && !locationContextValue.isLocationKnown) {
      void (async () => {
        const location = await Location.getCurrentPositionAsync({})
        setLocationContextValue(locationContextValue => ({
          ...locationContextValue,
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
          isLocationKnown: true
        }))
      })()
    }
  }

  const onApiClientUnauthorized = (): void => {
    onLogout()
  }

  apiClient.setOnUnauthorized(onApiClientUnauthorized)

  const screenForLogin = (
    <AccountNavigator onLoginSuccess={onLoginSuccess}></AccountNavigator>
  )

  const screenForAuthenticatedUser = (
    <Tab.Navigator
      initialRouteName={lastOpenedTab}
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          let iconName

          if (route.name === 'Profile') {
            iconName = focused ? 'person' : 'person-outline'
          } else if (route.name === 'AllBooks') {
            iconName = focused ? 'book' : 'book-outline'
          } else if (route.name === 'MyBooks') {
            iconName = focused ? 'bookmarks' : 'bookmarks-outline'
          } else if (route.name === 'Notifications') {
            iconName = focused ? 'notifications' : 'notifications-outline'
          }

          return <Ionicons name={iconName} size={size} color={color}/>
        },
        headerShown: false,
        tabBarShowLabel: false
      })}
    >
      <Tab.Screen name={TabAllBooks} options={{ title: t('tabs.library_title', 'Library') }}
                  children={() => <AllBooksTab onLocationPermissionChange={onLocationPermissionChange}/>}
                  listeners={{ tabPress: () => setLastOpenedTab(TabAllBooks) }}/>
      <Tab.Screen name={TabMyBooks} component={MyBooksTab} options={{ title: t('tabs.my_books_title', 'My Books') }}
                  listeners={{ tabPress: () => setLastOpenedTab(TabMyBooks) }}/>
      <Tab.Screen name={TabProfile} options={{ title: t('tabs.profile_title', 'Profile') }}
                  children={() => <ProfileScreen onLogout={onLogout}
                                                 onLocationPermissionChange={onLocationPermissionChange}/>}
                  listeners={{ tabPress: () => setLastOpenedTab(TabProfile) }}/>
      <Tab.Screen name={TabNotifications} options={{ title: t('tabs.notifications_title', 'Notifications') }}
                  children={() => <NotificationsTab/>}
                  listeners={{ tabPress: () => setLastOpenedTab(TabNotifications) }}/>
    </Tab.Navigator>
  )

  let content: JSX.Element

  switch (appState) {
    case 'ready':
      if (loggedIn) {
        content = screenForAuthenticatedUser
      } else {
        content = screenForLogin
      }
      break
    case 'offline-or-server-error':
      content = <OfflineOrServerErrorScreen onRetry={onRetry} />
      break
    case 'needs-update':
      content = updateScreen
      break
    default:
      content = loadingScreen
      break
  }

  const title = (pageTitle: any): string => {
    const cleanTitle: string = (pageTitle ?? '').toString().trim()
    if (cleanTitle.length > 0) {
      return t('app.title_with_page', '{{page}} - Distributed Library', { page: cleanTitle })
    }
    return t('app.base_title', 'Distributed Library')
  }

  return (
    <ApiAuthContext.Provider value={apiAuthContextValue}>
      <CoverCacheContext.Provider value={coverCacheContextValue}>
        <LocationContext.Provider value={locationContextValue}>
          <PaperProvider theme={CombinedLightTheme}>
            <NavigationContainer theme={CombinedLightTheme}
                                 documentTitle={{
                                   formatter: (options, route) =>
                                     title(options?.title ?? route?.name)
                                 }}>
              {content}
            </NavigationContainer>
          </PaperProvider>
        </LocationContext.Provider>
      </CoverCacheContext.Provider>
    </ApiAuthContext.Provider>
  )
}

export default App
