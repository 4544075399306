import React, { FC, useEffect, useRef } from 'react'
import { Platform, View } from 'react-native'
import { NativeHcaptcha } from './NativeHcaptcha'
import * as config from './../../app/config'
import { useTranslation } from 'react-i18next'

interface HcaptchaProps {
  onSetCode?: (code: string) => void
  onSetIsVerifiedHuman?: (isHuman: boolean) => void
}

export const Hcaptcha: FC<HcaptchaProps> = ({ onSetCode, onSetIsVerifiedHuman }) => {
  if (Platform.OS === 'web') {
    return <WebHcaptcha onSetCode={onSetCode} onSetIsVerifiedHuman={onSetIsVerifiedHuman}></WebHcaptcha>
  } else {
    return <NativeHcaptcha onSetCode={onSetCode} onSetIsVerifiedHuman={onSetIsVerifiedHuman}></NativeHcaptcha>
  }
}

interface WindowHcaptcha {
  hcaptcha: any
  hcaptcha_callback: any
  hcaptcha_loaded: any
}

const WebHcaptcha: FC<HcaptchaProps> = ({ onSetCode, onSetIsVerifiedHuman }) => {
  const { i18n } = useTranslation()
  const typedWindow: typeof window & WindowHcaptcha = window as (typeof window & WindowHcaptcha)
  const captchaRef = useRef<HTMLDivElement | null>(null)

  const hcaptchaLoaded = (): void => {
    typedWindow.hcaptcha.render(captchaRef.current, {
      sitekey: config.hcaptchaSitekey,
      callback: 'hcaptcha_callback'
    })
  }

  const hcaptchaCallback = (data): void => {
    onSetCode?.(data)
    onSetIsVerifiedHuman?.(true)
  }

  useEffect(() => {
    typedWindow.hcaptcha_callback = hcaptchaCallback
    typedWindow.hcaptcha_loaded = hcaptchaLoaded

    if (typedWindow.hcaptcha !== undefined) {
      typedWindow.hcaptcha_loaded()
    }

    const script = document.createElement('script')

    script.src = `https://js.hcaptcha.com/1/api.js?onload=hcaptcha_loaded&render=explicit&hl=${i18n.language}`
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
      typedWindow.hcaptcha_loaded = undefined
      typedWindow.hcaptcha_callback = undefined
    }
  }, [])

  return <View>
    <div ref={captchaRef} className={'h-captcha'} data-sitekey={config.hcaptchaSitekey}></div>
  </View>
}
