import React, { FC, useContext, useEffect, useState } from 'react'
import { FlatList, Platform, RefreshControl, Text } from 'react-native'
import { MemoMyBookListItem } from './MyBookListItem'
import { PlaceholderBookCover } from '../all/placeholder'
import { Button, List, Snackbar } from 'react-native-paper'
import { Book, BookWithOutgoingRequests, MyBookWithIncomingRequests } from '../book'
import { ApiAuthContext } from '../../../app/api_auth_context'
import { useCustomTheme } from '../../../app/themes'
import { useNavigation } from '@react-navigation/native'
import { MemoOnLoanBookListItem } from './OnLoanBookItem'
import { useTranslation } from 'react-i18next'

export interface MyBooksListScreenProps {
  dirty: boolean
  setDirty: (val: boolean) => void
  addBook: () => void
  bookDeleted: Book | null
  setBookDeleted: (book: Book | null) => void
}

const itemEmptyMyBooks = 'EMPTY_MY_BOOKS'
const itemEmptyOnLoanBooks = 'EMPTY_ON_LOAN_BOOKS'
const itemEmptyActiveRequestBooks = 'EMPTY_ACTIVE_REQUEST_BOOKS'

type DisplayListItemType = string | MyBookWithIncomingRequests | BookWithOutgoingRequests

export const MyBooksListScreen: FC<MyBooksListScreenProps> = ({ dirty, setDirty, addBook, bookDeleted, setBookDeleted }) => {
  const [books, setBooks] = useState<MyBookWithIncomingRequests[]>([])
  const [onLoanBooks, setOnLoanBooks] = useState<BookWithOutgoingRequests[]>([])
  const [activeOutgoingRequestBooks, setActiveOutgoingRequestBooks] = useState<BookWithOutgoingRequests[]>([])
  const [bookStatus, setBookStatus] = useState<'idle' | 'loading' | 'failed' | 'succeeded'>('idle')
  const [bookError, setBookError] = useState('')
  const [needUpdateDisplayBookList, setNeedUpdateDisplayBookList] = useState(false)
  const [displayBookList, setDisplayBookListWithHeaders] = useState<DisplayListItemType[]>([''])
  const [stickyHeaderIndices, setStickyHeaderIndices] = useState<number[]>([0])
  const [toastMessage, setToastMessage] = useState<string | null>(null)
  const navigation = useNavigation()
  const { apiClient } = useContext(ApiAuthContext)
  const busy = bookStatus === 'idle' || bookStatus === 'loading'
  const theme = useCustomTheme()

  const { t } = useTranslation()

  const trReload = t('common.reload', 'Reload')
  const trSubHeaderOnLoanToMe = t('my_books.on_loan_to_me_subheader', 'On loan to me')
  const trSubHeaderActiveRequestBooks = t('my_books.active_request_books_subheader', 'Ongoing')
  const trSubHeaderMyBooks = t('my_books.my_books_subheader', 'My books')
  const trEmptyOnLoanBooks = t('my_books.empty_on_loan_books', 'Go to the Library to find your next page turner')
  const trEmptyActiveRequestBooks = t('my_books.empty_active_request_books', 'Go to the Library to find your next page turner')
  const trEmptyMyBooks = t('my_books.empty_my_books', 'Add a book from your personal library')

  useEffect(() => {
    if (dirty) {
      setBookStatus('idle')
      setDirty(false)
    }
  }, [dirty])

  useEffect(() => {
    let ignore = false
    if (bookStatus === 'idle') {
      apiClient?.getMyBooksWithIncomingRequests()
        .then(books => {
          if (ignore) return
          books.sort((a, b) => {
            const aStatus = a.has_active_request ? 'a' : 'b'
            const bStatus = b.has_active_request ? 'a' : 'b'
            return (aStatus + a.name + a.id).localeCompare(bStatus + b.name + b.id)
          })
          setBooks(books)
          return apiClient?.getOnLoanBooksWithOutgoingRequests()
        })
        .then(books => {
          if (ignore) return
          if (books !== undefined) {
            books.sort((a, b) => {
              const aStatus = a.has_active_request ? 'a' : 'b'
              const bStatus = b.has_active_request ? 'a' : 'b'
              return (aStatus + a.name + a.id).localeCompare(bStatus + b.name + b.id)
            })
            setOnLoanBooks(books)
          }
          setBookStatus('succeeded')
          setNeedUpdateDisplayBookList(true)
        })
        .then(async () => {
          // noinspection ES6RedundantAwait
          return await apiClient?.getActiveOutgoingRequestBooks()
        })
        .then((books) => {
          books = books ?? []
          books = books.filter(b => !b.is_on_loan)
          setActiveOutgoingRequestBooks(books)
          setNeedUpdateDisplayBookList(true)
        })
        .catch(reason => {
          console.log('libelaye', reason)
          if (ignore) return
          setBookError(reason.toString())
          setBookStatus('failed')
        })
    }
    return () => { ignore = true }
  }, [apiClient, bookStatus])

  useEffect(() => {
    if (bookDeleted !== null) {
      setToastMessage(t('my_books.deleted_book_toast', 'Deleted {{book_name}}', { book_name: bookDeleted.name }))
      setBookDeleted(null)
    }
  }, [bookDeleted])

  const reload = (): void => {
    setBookStatus('idle')
  }

  useEffect(() => {
    if (needUpdateDisplayBookList) {
      const myBooksWhenEmpty = books.length === 0 ? [itemEmptyMyBooks] : []
      const onLoanBooksWhenEmpty = onLoanBooks.length === 0 ? [itemEmptyOnLoanBooks] : []
      const activeOutgoingRequestBooksWhenEmpty = activeOutgoingRequestBooks.length === 0 ? [itemEmptyActiveRequestBooks] : []

      const newDisplayList: DisplayListItemType[] = []

      // if (activeOutgoingRequestBooks.length > 0) {
      newDisplayList.push(
          `${trSubHeaderActiveRequestBooks} (${activeOutgoingRequestBooks.length})`,
          ...activeOutgoingRequestBooks,
          ...activeOutgoingRequestBooksWhenEmpty
      )
      // }

      newDisplayList.push(
        `${trSubHeaderOnLoanToMe} (${onLoanBooks.length})`,
        ...onLoanBooks,
        ...onLoanBooksWhenEmpty
      )

      newDisplayList.push(
        `${trSubHeaderMyBooks} (${books.length})`,
        ...books,
        ...myBooksWhenEmpty
      )

      setDisplayBookListWithHeaders(newDisplayList)

      setStickyHeaderIndices([0, onLoanBooks.length + onLoanBooksWhenEmpty.length + 1])
      setNeedUpdateDisplayBookList(false)
    }
  }, [needUpdateDisplayBookList, books, displayBookList, stickyHeaderIndices])

  if (bookStatus === 'failed') {
    return <Text>{bookError} / <Button onPress={reload} style={{ margin: 16 }}
                                       mode={'outlined'}>{trReload}</Button></Text>
  }

  const refreshing = bookStatus === 'idle' || bookStatus === 'loading'
  return (<>
      <FlatList data={displayBookList} refreshControl={<RefreshControl refreshing={refreshing} onRefresh={reload}/>}
                stickyHeaderIndices={stickyHeaderIndices}
                renderItem={({ item }) => {
                  if (item === itemEmptyActiveRequestBooks) {
                    // @ts-expect-error
                    return <List.Item title={trEmptyActiveRequestBooks} onPress={() => navigation.navigate('AllBooks')}></List.Item>
                  }
                  if (item === itemEmptyOnLoanBooks) {
                    // @ts-expect-error
                    return <List.Item title={trEmptyOnLoanBooks} onPress={() => navigation.navigate('AllBooks')}></List.Item>
                  }
                  if (item === itemEmptyMyBooks) {
                    return <List.Item title={trEmptyMyBooks} onPress={addBook}></List.Item>
                  }
                  if (typeof item === 'string') {
                    return <List.Subheader style={{ backgroundColor: theme.colors.background }}>{item}</List.Subheader>
                  }
                  if (item.is_owned_by_me) {
                    return <MemoMyBookListItem book={item} imageDataUrl={PlaceholderBookCover}/>
                  } else {
                    if (item.is_on_loan) {
                      return <MemoOnLoanBookListItem book={item} imageDataUrl={PlaceholderBookCover}/>
                    } else {
                      return <MemoOnLoanBookListItem book={item} imageDataUrl={PlaceholderBookCover}/>
                    }
                  }
                }}>
      </FlatList>
      {Platform.OS === 'web' &&
        <Button onPress={() => reload()} mode={'outlined'} style={{ margin: 16 }}
                disabled={busy} loading={busy}>{trReload}</Button>
      }
      <Snackbar style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
                visible={toastMessage !== null}
                onDismiss={() => setToastMessage(null)}
                action={{ label: 'OK', onPress: () => setToastMessage(null) }}>{toastMessage}</Snackbar>
    </>
  )
}
