/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ErrorResponse,
  GetMyBookResonse,
  GetMyBooksResonse,
  SetIsRentableRequest,
  SuccessResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class MyBooks<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetMyBooks
   * @request GET:/my-books
   */
  getMyBooks = (params: RequestParams = {}) =>
    this.request<GetMyBooksResonse, any>({
      path: `/my-books`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name GetMyBook
   * @request GET:/my-books/{id}
   */
  getMyBook = (id: string, params: RequestParams = {}) =>
    this.request<GetMyBookResonse, ErrorResponse>({
      path: `/my-books/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name DeleteMyBook
   * @request POST:/my-books/{id}/delete
   */
  deleteMyBook = (id: string, params: RequestParams = {}) =>
    this.request<SuccessResponse, ErrorResponse>({
      path: `/my-books/${id}/delete`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name SetIsRentable
   * @request PATCH:/my-books/{id}/set-is-rentable
   */
  setIsRentable = (id: string, Body: SetIsRentableRequest, params: RequestParams = {}) =>
    this.request<SuccessResponse, ErrorResponse>({
      path: `/my-books/${id}/set-is-rentable`,
      method: "PATCH",
      body: Body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
