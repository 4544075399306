import { createContext } from 'react'
import { ApiClient } from '../features/api/client'

export interface ApiAuthContextValue {
  loggedIn: boolean
  accessToken: string | null
  apiClient: ApiClient | null
  authUser: AuthUser | null
}

export interface AuthUser {
  id: string
  email: string
}

const initialValue: ApiAuthContextValue = {
  loggedIn: false,
  accessToken: null,
  apiClient: null,
  authUser: null
}

export const ApiAuthContext = createContext<ApiAuthContextValue>(initialValue)
