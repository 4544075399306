import React, { FC, useContext, useState } from 'react'
import { ApiAuthContext } from '../../../app/api_auth_context'
import { Button } from 'react-native-paper'
import { View } from 'react-native'
import { PrettyTextInput } from '../../widgets/PrettyTextInput'
import { useTranslation } from 'react-i18next'

interface CreateBookProps {
  onCreated?: () => void
  onCancel?: () => void
  prefilledIsbn?: string
}

export const CreateBook: FC<CreateBookProps> = (props) => {
  const [title, setTitle] = useState('')
  const [author, setAuthor] = useState('')
  const [publishDate, setPublishDate] = useState('')
  const [isbn, setIsbn] = useState(props.prefilledIsbn ?? '')

  const { t } = useTranslation()

  const trRequired = ' *'
  const trBookTitleLabel = t('create_book.title_label', 'Title') + trRequired
  const trBookAuthorLabel = t('create_book.author_label', 'Author')
  const trBookPublishDateLabel = t('create_book.publish_date_label', 'Publish Date (yyyy)')
  const trBookIsbnLabel = t('create_book.isbn_label', 'ISBN (10 or 13 digits)') + trRequired
  const trSubmit = t('common.create', 'Create')

  const { apiClient } = useContext(ApiAuthContext)

  const onFormSubmit = (): void => {
    const cleanTitle = title.trim()
    const cleanIsbn = isbn.replace(/[^0-9]/g, '')
    const cleanAuthors = [author.trim()]
    const cleanPublishDate = publishDate.replace(/[^0-9]/g, '')
    void apiClient?.createBook(cleanTitle, cleanIsbn, cleanAuthors, cleanPublishDate).then((bookId: string) => {
      console.log('successfully created')
      setTitle('')
      props.onCreated?.()
    }).catch(reason => {
      console.error(reason)
    })
  }

  const cleanIsbn = (isbn: string): string => {
    return isbn.replace(/[^0-9- ]/g, '')
  }

  const updateIsbn = (isbn: string): void => {
    isbn = cleanIsbn(isbn)
    const numbers = isbn.replace(/[^0-9]/g, '').length
    if (numbers <= 13) {
      setIsbn(isbn)
    }
  }

  const updateTitle = (title: string): void => {
    if (title.trim().length <= 1000) {
      setTitle(title)
    }
  }

  const updateAuthor = (author: string): void => {
    if (author.trim().length <= 1000) {
      setAuthor(author)
    }
  }

  const cleanPublishDate = (publishDate: string): string => {
    return publishDate.replace(/[^0-9]/g, '')
  }

  const updatePublishDate = (publishDate: string): void => {
    publishDate = cleanPublishDate(publishDate)
    const numbers = publishDate.replace(/[^0-9]/g, '').length
    if (numbers <= 4) {
      setPublishDate(publishDate)
    }
  }

  const isTitleValid = (): boolean => {
    return title.trim().length > 0
  }

  const isIsbnValid = (): boolean => {
    const countDigits = isbn.replace(/[^0-9]/g, '').length
    return countDigits === 13 || countDigits === 10
  }

  const isEntireFormValid = (): boolean => {
    return isTitleValid() && isIsbnValid()
  }

  const onClickCancel = (): void => {
    props.onCancel?.()
  }

  return (
    <>
      <PrettyTextInput value={title}
                       onChangeText={updateTitle} style={{ marginTop: 16 }} label={trBookTitleLabel}/>
      <PrettyTextInput value={author}
                       onChangeText={updateAuthor} style={{}} label={trBookAuthorLabel}/>
      <PrettyTextInput value={publishDate}
                       onChangeText={updatePublishDate} style={{}} label={trBookPublishDateLabel}/>
      <PrettyTextInput value={isbn}
                       onChangeText={updateIsbn} style={{}} label={trBookIsbnLabel}/>
      <View style={{ flexDirection: 'row', marginTop: 16 }}>
        <Button onPress={e => onFormSubmit()} mode={'contained'} style={{ flex: 1 }}
                disabled={!isEntireFormValid()}>{trSubmit}</Button>
        <Button onPress={onClickCancel} mode={'text'} style={{ flex: 1 }}>{t('common.cancel', 'Cancel')}</Button>
      </View>
    </>
  )
}
