import React, { FC, useEffect, useState } from 'react'
import { BarCodeScannedCallback, BarCodeScanner } from 'expo-barcode-scanner'
import { StyleSheet, Text, View } from 'react-native'
import { Camera } from 'expo-camera'
import { useTranslation } from 'react-i18next'

export interface BarcodeProps {
  onScanned: (code: string) => void
}

export const Barcode: FC<BarcodeProps> = ({ onScanned }) => {
  const { t } = useTranslation()
  const [hasPermission, setHasPermission] = useState<boolean | null>(null)
  const [scanned, setScanned] = useState(false)

  useEffect(() => {
    const getBarCodeScannerPermissions = async (): Promise<void> => {
      const { status } = await BarCodeScanner.requestPermissionsAsync()
      setHasPermission(status === 'granted')
    }

    void getBarCodeScannerPermissions()
  }, [])

  const handleBarCodeScanned: BarCodeScannedCallback = ({ type, data }): void => {
    setScanned(true)
    onScanned(data)
  }

  if (hasPermission === null) {
    return <Text>{t('barcode.requesting_for_camera_permission', 'Requesting for camera permission')}</Text>
  }
  if (!hasPermission) {
    return <Text>{t('barcode.no_access_to_camera', 'No access to camera')}</Text>
  }

  return (
    <View style={styles.container}>
      <Camera
        ratio={'1:1'}
        barCodeScannerSettings={{ barCodeTypes: ['ean8', 'ean13'] }}
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={styles.camera}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center'
  },
  camera: {
    flex: 1
  }
})
