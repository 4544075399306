import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Book, MyBookWithIncomingRequests } from '../book'
import { IncomingRequest } from '../../../client/data-contracts'
import { ApiAuthContext } from '../../../app/api_auth_context'
import { Alert, Platform, ScrollView, Text, View } from 'react-native'
import { IncomingRequestBox } from '../IncomingRequestBox'
import { Button, Switch } from 'react-native-paper'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

export interface DetailScreenProps {
  onUpdateFromDetailScreen?: () => void
  onBookDeleteFromDetailScreen?: (book: Book) => void
}

export const DetailScreen: FC<DetailScreenProps> = ({ onUpdateFromDetailScreen, onBookDeleteFromDetailScreen }) => {
  const route = useRoute()
  // @ts-expect-error
  const book: MyBookWithIncomingRequests = useMemo(() => route.params?.book, [route])

  const [incomingRequests, setIncomingRequests] = useState<IncomingRequest[]>([])
  const [isRentable, setIsRentable] = useState(book.is_rentable)
  const [deleteError, setDeleteError] = useState<string | null>(null)

  const { apiClient } = useContext(ApiAuthContext)

  const navigation = useNavigation()

  const { t } = useTranslation()

  useEffect(() => {
    navigation.setOptions({ title: book.name, headerTitleStyle: { ellipsizeMode: 'tail' } })
  }, [])

  const reloadIncomingRequests = (): () => void => {
    let ignore = false
    void apiClient?.getIncomingRequestsForBook(book).then(value => {
      if (ignore) return
      const newestFirst = (a, b): number => (b.created_at).localeCompare(a.created_at)
      value.sort(newestFirst)
      setIncomingRequests(value)
    })
    return (): void => { ignore = true }
  }

  useEffect(() => {
    return reloadIncomingRequests()
  }, [])

  const onToggleRentable = (): void => {
    const newValue = !isRentable
    setIsRentable(newValue)
    void apiClient?.setIsRentable(book, newValue)
      .then(() => onUpdateFromDetailScreen?.())
  }

  const onIncomingRequestBoxUpdate = (): void => {
    onUpdateFromDetailScreen?.()
    reloadIncomingRequests()
  }

  const deleteThisBook = (): void => {
    setDeleteError(null)
    void apiClient?.deleteBook(book.id)
      .then(() => {
        onBookDeleteFromDetailScreen?.(book)
        // @ts-expect-error
        navigation.navigate('My books')
      })
      .catch(reason => {
        setDeleteError(reason.toString())
      })
  }

  const onDelete = (): void => {
    const trTitle = t('my_books_detail.confirm_are_you_sure_remove_book_title', 'Delete?')
    const trMessage = t('my_books_detail.confirm_are_you_sure_remove_book_message', 'Are you sure you want to remove this book from your collection?')
    if (Platform.OS === 'web') {
      const result = window.confirm(trMessage)
      if (result) {
        deleteThisBook()
      }
    } else {
      Alert.alert(trTitle, trMessage, [
        {
          text: t('common.yes', 'Yes'),
          onPress: () => {
            deleteThisBook()
          }
        },
        { text: t('common.no', 'No') }
      ])
    }
  }

  return (
    <ScrollView style={{ flex: 1 }}>
      <View style={{ padding: 15, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ flex: 1 }}>{t('my_books_detail.rentable', 'Rentable')}</Text>
        <Switch value={isRentable} onValueChange={onToggleRentable}/>
      </View>
      {incomingRequests.length === 0 && <View style={{ padding: 15 }}><Text>{t('my_books_detail.no_incoming_loan_requests', 'No incoming loan requests')}</Text></View>}
      {incomingRequests.length > 0 && incomingRequests.map(r => {
        return <View key={r.id} style={{
          borderBottomWidth: 1,
          borderBottomColor: '#ddd',
          padding: 15
        }}>
          <IncomingRequestBox onRequestUpdate={onIncomingRequestBoxUpdate}
                              request={r}/>
        </View>
      })}
      <View style={{ padding: 15 }}>
        <Button mode={'outlined'} onPress={onDelete}>{t('my_books_detail.delete_book', 'Delete Book')}</Button>
        {deleteError !== null && <Text>{deleteError}</Text>}
      </View>
    </ScrollView>
  )
}
