import * as Location from 'expo-location'
import { Platform } from 'react-native'

export type LocationPermissionStatus = 'granted' | 'undetermined' | 'denied' | 'unsupported'

export const getLocationPermissionStatus = async (): Promise<LocationPermissionStatus> => {
  if (Platform.OS === 'web' && navigator.permissions !== undefined) {
    return await navigator.permissions
      .query({ name: 'geolocation' })
      .then(value => {
        switch (value.state) {
          case 'granted':
          case 'denied':
            return value.state
          case 'prompt':
            return 'undetermined'
        }
      })
  } else {
    return await Location.getForegroundPermissionsAsync()
      .then(value => {
        switch (value.status) {
          case 'denied':
          case 'granted':
          case 'undetermined':
            return value.status
          default:
            return 'undetermined'
        }
      })
  }
}

export const askForPermissions = async (): ReturnType<typeof Location.requestForegroundPermissionsAsync> => {
  return await Location.requestForegroundPermissionsAsync()
}

export const getCurrentLocation = async (): ReturnType<typeof Location.getCurrentPositionAsync> => {
  return await Location.getCurrentPositionAsync({})
}
