import { registerRootComponent } from 'expo'
import * as config from './app/config'

import App from './App'
import { Platform } from 'react-native'

if (config.newrelicEnabled && ['ios', 'android'].includes(Platform.OS)) {
  require('./app/newrelic.ts').enableNewrelic(App)
}

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App)
