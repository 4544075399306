import React, { FC, useCallback } from 'react'
import { Text, View } from 'react-native'
import { Button, Title } from 'react-native-paper'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

export const CongratulationsScreen: FC = () => {
  const navigation = useNavigation()
  const { t } = useTranslation()

  const onClickOk = useCallback(() => {
    // @ts-expect-error
    navigation.navigate('login')
  }, [])

  return <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', padding: 15 }}>
        <Title style={{ textAlign: 'center', marginBottom: 15 }}>{t('signup.congratulations', 'Congatulations!')}</Title>
        <Text style={{ textAlign: 'center' }}>{t('signup.account_created', 'Your account has been created.')}</Text>
        <Text style={{ textAlign: 'center', marginTop: 15 }}>{t('signup.confirmation_email_sent', 'We\'ve sent you an email with a confirmation link. Once you\'ve confirmed your account, you can log in.')}</Text>
        <Button style={{ marginTop: 15 }} onPress={onClickOk}>{t('signup.go_to_login', 'Go to login')}</Button>
    </View>
}
