import React, { FC, useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { Checkbox } from 'react-native-paper'
import { useTranslation } from 'react-i18next'

interface DummyCaptchaProps {
  onSetCode?: (code: string) => void
  onSetIsVerifiedHuman?: (isHuman: boolean) => void
}

export const DummyCaptcha: FC<DummyCaptchaProps> = ({ onSetCode, onSetIsVerifiedHuman }) => {
  const { t } = useTranslation()
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    onSetCode?.('not human')
  }, [])

  const onPress = (): void => {
    const newVal = !checked
    setChecked(newVal)
    if (newVal) {
      onSetCode?.('yes human')
      onSetIsVerifiedHuman?.(true)
    } else {
      onSetCode?.('not human')
      onSetIsVerifiedHuman?.(false)
    }
  }

  return <View>
    <TouchableOpacity onPress={onPress} style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Checkbox status={checked ? 'checked' : 'unchecked'}/>
      <Text>{t('captcha.i_am_human', 'I am human')}</Text>
    </TouchableOpacity>
  </View>
}
