import React, { FC, useContext, useEffect, useState } from 'react'
import { Button, SegmentedButtons, TextInput, Title } from 'react-native-paper'
import { ActivityIndicator, Image, Platform, Text, View, StyleSheet } from 'react-native'
import { ApiAuthContext } from '../../app/api_auth_context'
import { CreateBook } from '../books/mybooks/CreateBook'
import { Barcode } from './barcode'
import { SearchIsbnResponse } from '../../client/data-contracts'
import { debugSimulateScanFail, debugSimulateScanOk } from '../../app/config'
import { useTranslation } from 'react-i18next'

interface DialogProps {
  onCreated: () => void
  onDismiss: () => void
}

export const DialogScanAndManual: FC<DialogProps> = ({ onCreated, onDismiss }) => {
  const { t } = useTranslation()
  const [type, setType] = useState<'scan' | 'manual'>('scan')
  const [prefilledIsbn, setPrefilledIsbn] = useState<string | undefined>(undefined)

  const onManuallyAddMissingDetails = (isbn: string): void => {
    setPrefilledIsbn(isbn)
    setType('manual')
  }

  let dialog: JSX.Element
  if (type === 'scan') {
    dialog = <ScanDialog onCreated={onCreated} onDismiss={onDismiss}
                         onManuallyAddMissingDetails={onManuallyAddMissingDetails}/>
  } else {
    dialog = <ManualDialog onCreated={onCreated} onDismiss={onDismiss} prefilledIsbn={prefilledIsbn}/>
  }

  return <View>
    <SegmentedButtons
      style={{ marginBottom: 15 }}
      density={'medium'}
      value={type}
      onValueChange={setType as unknown as (value: string) => void}
      buttons={[
        {
          value: 'scan',
          label: t('barcode.scan_label', 'Scan'),
          icon: 'barcode-scan'
        },
        {
          value: 'manual',
          label: t('barcode.manual_label', 'Manual'),
          icon: 'pencil'
        }
      ]}
    />
    {dialog}
  </View>
}

export const OnlyManualDialog: FC<DialogProps> = ({ onCreated, onDismiss }) => {
  return <View>
    <ManualDialog onCreated={onCreated} onDismiss={onDismiss}/>
  </View>
}

interface ScanDialogProps extends DialogProps {
  onManuallyAddMissingDetails: (isbn: string) => void
}

const ScanDialog: FC<ScanDialogProps> = ({ onCreated, onDismiss, onManuallyAddMissingDetails }): JSX.Element => {
  const { t } = useTranslation()
  const [state, setState] = useState<'scanning' | 'searching' | 'not-found' | 'found'>('scanning')
  const [isbnCode, setIsbnCode] = useState<string | null>(null)
  const [customSimulateCode, setCustomSimulateCode] = useState<string>('')
  const [searchResult, setSearchResult] = useState<SearchIsbnResponse | null>(null)

  const { apiClient } = useContext(ApiAuthContext)

  const [width, setWidth] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)

  useEffect(() => {
    if (state === 'searching') {
      search()
    }
  }, [state])

  useEffect(() => {
    if (typeof searchResult?.cover_medium === 'string' && searchResult.cover_medium !== '') {
      Image.getSize(searchResult.cover_medium, (width, height) => {
        setWidth(width)
        setHeight(height)
      })
    }
  }, [searchResult])

  const onScanned = (code: string): void => {
    setIsbnCode(code)
    setState('searching')
  }

  const simulateOkCode = (): void => {
    setIsbnCode(debugSimulateScanOk)
    setState('searching')
  }

  const simulateNotOkCode = (): void => {
    setIsbnCode(debugSimulateScanFail)
    setState('searching')
  }

  const simulateCustomCode = (): void => {
    setIsbnCode(customSimulateCode)
    setState('searching')
  }

  const search = (): void => {
    void apiClient?.searchIsbn(isbnCode ?? '')
      .then((result) => {
        if (result === null) {
          setSearchResult(null)
          setState('not-found')
        } else {
          setSearchResult(result)
          setState('found')
        }
      })
      .catch(reason => {
        console.log(reason)
        setSearchResult(null)
        setState('not-found')
      })
  }

  const cancel = (): void => {
    onDismiss()
  }

  const add = (): void => {
    if (searchResult !== null) {
      void apiClient?.createBookByIsbn(isbnCode ?? '')
        .then(onCreated)
        .catch(reason => {
          alert(t('barcode.could_not_create_please_try_again', 'Could not create. Please try again.'))
          console.error(reason)
        })
    }
  }

  const scanAgain = (): void => {
    setState('scanning')
    setIsbnCode(null)
  }

  const manualAdd = (): void => {
    onManuallyAddMissingDetails(isbnCode ?? '')
  }

  if (state === 'scanning') {
    return <>
      <View style={styles.grayBorderContainer}>
        {Platform.OS === 'web' && (
          <>
            <Text>{t('barcode.scanning', 'Scanning…')}</Text>
            <Button onPress={simulateOkCode}>{t('barcode.simulate_ok_code', 'Simulate ok code')}</Button>
            <Button onPress={simulateNotOkCode}>{t('barcode.simulate_nok_code', 'Simulate nok code')}</Button>
            <View style={{ flexDirection: 'column' }}>
              <TextInput value={customSimulateCode} onChangeText={setCustomSimulateCode} placeholder={'custom code'}></TextInput>
              <Button onPress={simulateCustomCode}>{t('barcode.simulate_custom_code', 'Simulate custom code')}</Button>
            </View>
          </>
        )}
        {Platform.OS !== 'web' && (
          <View style={{ width: '100%', height: '100%' }}>
            <Barcode onScanned={onScanned}></Barcode>
          </View>
        )}
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Button onPress={add} disabled={true} style={{ flex: 1 }} mode={'contained'}>{t('common.add', 'Add')}</Button>
        <Button onPress={cancel} style={{ flex: 1 }}>{t('common.cancel', 'Cancel')}</Button>
      </View>
    </>
  } else if (state === 'searching') {
    return <>
      <View style={styles.grayBorderContainer}>
        <ActivityIndicator size={'large'}></ActivityIndicator>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Button onPress={cancel} mode={'contained-tonal'} style={{ flex: 1 }}>{t('common.cancel', 'Cancel')}</Button>
      </View>
    </>
  } else if (state === 'not-found') {
    return <>
      <View style={styles.grayBorderContainer}>
        <Title>{isbnCode}</Title>
        <Text style={{ textAlign: 'center' }}>{t('barcode.isbn_not_exist_in_database', 'Sorry, this ISBN code does not seem to exist in our database.')}</Text>
        <View>
          <Button mode={'outlined'} style={{ marginTop: 15 }} onPress={manualAdd}>{t('barcode.manually_add_missing_details', 'Manually add missing details')}</Button>
        </View>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Button onPress={scanAgain} style={{ flex: 1 }} mode={'contained'}>{t('barcode.scan_again', 'Scan again')}</Button>
        <Button onPress={cancel} style={{ flex: 1 }}>{t('common.cancel', 'Cancel')}</Button>
      </View>
    </>
  } else if (state === 'found') {
    return <>
      <View style={styles.grayBorderContainer}>
        <Image source={{ uri: searchResult?.cover_medium ?? '' }}
               style={{ width, height, maxHeight: 260, maxWidth: 260 }} resizeMode={'contain'}></Image>
      </View>
      <Text style={{ fontSize: 18 }}>{searchResult?.title}</Text>
      <Text style={{ marginTop: 5, fontSize: 14 }}>{searchResult?.authors.join(', ') ?? t('common.unknown', 'Unknown')}</Text>
      <Text style={{ marginTop: 5, fontSize: 14, color: '#999' }}>{searchResult?.publish_date ?? t('common.unknown', 'Unknown')}</Text>
      <View style={{ marginTop: 12, flexDirection: 'row', alignItems: 'center' }}>
        <Button onPress={add} style={{ flex: 1 }} mode={'contained'}>{t('common.add', 'Add')}</Button>
        <Button onPress={cancel} style={{ flex: 1 }}>{t('common.cancel', 'Cancel')}</Button>
      </View>
    </>
  }

  return <Text>{t('barcode.scan', 'Scan')}</Text>
}

interface ManualDialogProps extends DialogProps {
  prefilledIsbn?: string
}

const ManualDialog: FC<ManualDialogProps> = ({ onCreated, prefilledIsbn, onDismiss }): JSX.Element => {
  return <CreateBook onCreated={onCreated} prefilledIsbn={prefilledIsbn} onCancel={onDismiss}></CreateBook>
}

const styles = StyleSheet.create({
  grayBorderContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 280,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#eee',
    position: 'relative',
    marginHorizontal: 'auto',
    padding: 10,
    marginBottom: 15
  }
})
