/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ErrorResponse, JsonMarkBookRequestPickedUpRequest, SuccessResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class MarkBookRequestPickedUp<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name MarkBookRequestPickedUp
   * @request POST:/mark-book-request-picked-up
   */
  markBookRequestPickedUp = (Body: JsonMarkBookRequestPickedUpRequest, params: RequestParams = {}) =>
    this.request<SuccessResponse, ErrorResponse>({
      path: `/mark-book-request-picked-up`,
      method: "POST",
      body: Body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
