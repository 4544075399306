import { createContext } from 'react'
import { LocationPermissionStatus } from '../../app/location'

export interface LocationContextValue {
  latitude: number
  longitude: number
  isLocationKnown: boolean
  locationPermissionStatus: LocationPermissionStatus | null
}

export const defaultLocationContextValue: LocationContextValue = {
  latitude: 0,
  longitude: 0,
  isLocationKnown: false,
  locationPermissionStatus: null
}

export const usualSuspectSrid = 4326

export const LocationContext = createContext<LocationContextValue>(defaultLocationContextValue)
