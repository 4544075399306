import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { BookWithOutgoingRequests } from '../book'
import { ActivityIndicator, FlatList, Platform, RefreshControl, Text, View } from 'react-native'
import { Button, Chip, IconButton, Searchbar } from 'react-native-paper'
import CheckBox from '../../widgets/Checkbox'
import { ApiAuthContext } from '../../../app/api_auth_context'
import { MemoBookListItem } from './BookListItem'
import { LocationContext, usualSuspectSrid } from '../../location/LocationContext'
import { GetAllBooksOptions } from '../../api/client'
import { useCustomTheme } from '../../../app/themes'
import { useDebounce } from 'use-debounce'
import { thumbHashToDataURL } from 'thumbhash'
import 'core-js/modules/web.atob'
import { blobToBase64, PlaceholderBookCover } from './placeholder'
import { askForPermissions, LocationPermissionStatus } from '../../../app/location'
import { useTranslation } from 'react-i18next'

type SortByType = 'title' | 'added' | 'authors' | 'distance'

export interface AllBooksScreenFilter {
  query: string
  showFilter: boolean
  sortBy: SortByType
  sortReverse: boolean
  showOnlyAvailableBooks: boolean
  showYourBooks: boolean
}

export const getDefaultAllBooksScreenFilter = (): AllBooksScreenFilter => {
  return {
    query: '',
    showFilter: false,
    sortBy: 'added',
    sortReverse: false,
    showOnlyAvailableBooks: true,
    showYourBooks: false
  }
}

export interface AllBooksScreenProps {
  filter: AllBooksScreenFilter
  onUpdateFilter: (filter: AllBooksScreenFilter) => void
  dirty: boolean
  setDirty: (val: boolean) => void
  onLocationPermissionChange: (val: LocationPermissionStatus) => void
}

const BOOKS_PER_PAGE = 25

interface Thumb {
  image?: string
  thumbhash?: string
  done: boolean
}

interface LocationPermissionBoxProps {
  onLocationPermissionChange: (val: LocationPermissionStatus) => void
  onClose: () => void
}

const LocationPermissionBox: FC<LocationPermissionBoxProps> = ({ onLocationPermissionChange, onClose }) => {
  const { t } = useTranslation()

  const onYesPlease = (): void => {
    void askForPermissions().then(response => {
      const value = response.status as LocationPermissionStatus
      onLocationPermissionChange(value)
      onClose()
    })
  }

  const onNotRightNow = (): void => {
    onClose()
  }

  return (
    <View style={{ backgroundColor: '#eee', flexDirection: 'row', padding: 15, alignItems: 'center', justifyContent: 'center' }}>
      <Button mode={'contained'} style={{ flex: 1 }} onPress={onYesPlease}>{t('all_books.grant_location_permissions', 'Grant location permissions')}</Button>
      <Button mode={'outlined'} style={{ marginLeft: 15 }} onPress={onNotRightNow}>{t('all_books.not_right_now', 'Not right now')}</Button>
    </View>
  )
}

export const AllBooksScreen: FC<AllBooksScreenProps> = ({ filter, onUpdateFilter, dirty, setDirty, onLocationPermissionChange }) => {
  const [books, setBooks] = useState<BookWithOutgoingRequests[]>([])
  const [bookStatus, setBookStatus] = useState<'idle' | 'loading' | 'failed' | 'succeeded'>('idle')
  const [bookError, setBookError] = useState('')

  const [currentRequest, setCurrentRequest] = useState<'first-page' | 'next-page' | undefined>(undefined)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentRequestCounter, setCurrentRequestCounter] = useState(1)

  const [flatList, setFlatList] = useState<FlatList<BookWithOutgoingRequests> | null>(null)

  const [debouncedFilter, setDebouncedFilter] = useDebounce<AllBooksScreenFilter | undefined>(undefined, 250)

  const [noMorePagesToLoad, setNoMorePagesToLoad] = useState(false)

  const [thumbhashes, setThumbhashes] = useState<Record<string, Thumb>>({})
  const [images, setImages] = useState<Record<string, Thumb>>({})

  const [currentViewableItems, setCurrentViewableItems] = useState<BookWithOutgoingRequests[]>([])

  const [showLocationPermissionBox, setShowLocationPermissionBox] = useState(true)

  const { t } = useTranslation()

  const theme = useCustomTheme()

  const [query, setQuery] = useState(filter.query)
  const [showFilter, setShowFilter] = useState(filter.showFilter)
  const [sortBy, setSortBy] = useState<SortByType>(filter.sortBy)
  const [sortReverse, setSortReverse] = useState(filter.sortReverse)
  const [showOnlyAvailableBooks, setShowOnlyAvailableBooks] = useState(filter.showOnlyAvailableBooks)
  const [showYourBooks, setShowYourBooks] = useState(filter.showYourBooks)

  // const parseThumbhash = (book: BookWithOutgoingRequests): void => {
  //   const thumb: Thumb = thumbhashes[book.id] ?? { done: false }
  //   const dataUrl = thumbHashToDataURL(Uint8Array.from(atob(book.cover_thumb_hash), c => c.charCodeAt(0)))
  //   const updated: Thumb = { ...thumb, thumbhash: dataUrl, done: true }
  //   setThumbhashes({ ...thumbhashes, [book.id]: updated })
  // }

  const downloadImage = useCallback((book: BookWithOutgoingRequests): () => void => {
    const thumb: Thumb = images[book.id] ?? { done: false }

    let ignore = false
    const cancel = (): void => { ignore = true }

    void apiAuthContext.apiClient?.rawCoverThumbnailFetch(book.id)
      .then((response) => {
        if (ignore) return

        if (response.status === 200) {
          void response.blob()
            .then(blobToBase64)
            .then((base64data) => {
              const updated: Thumb = { ...thumb, image: base64data as string, done: true }
              setImages(images => ({ ...images, [book.id]: updated }))
            })
            .catch(() => {
              const updated: Thumb = { ...thumb, done: true }
              setImages(images => ({ ...images, [book.id]: updated }))
            })
        } else {
          const updated: Thumb = { ...thumb, done: true }
          setImages(images => ({ ...images, [book.id]: updated }))
        }
      })
      .catch(() => {
        if (ignore) return

        const updated: Thumb = { ...thumb, done: true }
        setImages(images => ({ ...images, [book.id]: updated }))
      })

    return cancel
  }, [images])

  const getThumbDataUrl = useCallback((book: BookWithOutgoingRequests): string => {
    return images[book.id]?.image ?? thumbhashes[book.id]?.thumbhash ?? PlaceholderBookCover
  }, [images, thumbhashes])

  // useEffect(() => {
  //   for (let i = 0; i < currentViewableItems.length; i++) {
  //     const item = currentViewableItems[i]
  //     const thumb = thumbhashes[item.id]
  //     if (item.has_cover && (thumb === undefined || !thumb.done)) {
  //       parseThumbhash(item)
  //       return
  //     }
  //   }
  // }, [thumbhashes, currentViewableItems])

  useEffect(() => {
    for (let i = 0; i < currentViewableItems.length; i++) {
      const book = currentViewableItems[i]
      const thumb = images[book.id]
      if (book.has_cover && (thumb === undefined || !thumb.done)) {
        return downloadImage(book)
      }
    }
  }, [images, currentViewableItems])

  useEffect(() => {
    if (dirty) {
      setBookStatus('idle')
      setDirty(false)
    }
  }, [dirty])

  const trReload = t('common.reload', 'Reload')
  const busy = bookStatus === 'idle' || bookStatus === 'loading'

  const apiAuthContext = useContext(ApiAuthContext)
  const { latitude, longitude, isLocationKnown, locationPermissionStatus } = useContext(LocationContext)

  useEffect(() => {
    if (bookStatus === 'idle') {
      doFirstPageRequest()
    }
  }, [bookStatus])

  useEffect(() => {
    reload()
  }, [latitude, longitude, debouncedFilter])

  useEffect(() => {
    updateFilter()
  }, [query, showFilter, sortBy, sortReverse, showOnlyAvailableBooks, showYourBooks])

  const onViewableItemsChanged = useRef(({ viewableItems }) => {
    setCurrentViewableItems(viewableItems.map(v => v.item))
  })

  const buildGetAllBooksOptions = (): GetAllBooksOptions => {
    const options: GetAllBooksOptions = {}
    if (isLocationKnown) {
      options.lat = latitude
      options.lon = longitude
      options.srid = usualSuspectSrid
    }
    if (filter.query !== '') {
      options.query = filter.query
    }
    if (!filter.showYourBooks) {
      options.must_not_be_yours = true
    }
    if (filter.showOnlyAvailableBooks) {
      options.must_be_rentable = true
      options.must_not_be_on_loan = true
    }
    options.sort = filter.sortBy

    switch (options.sort) {
      case 'added':
        options.dir = filter.sortReverse ? 'asc' : 'desc'
        break
      default:
        options.dir = filter.sortReverse ? 'desc' : 'asc'
        break
    }

    return options
  }

  const doFirstPageRequest = (): void => {
    setCurrentRequest('first-page')

    setCurrentRequestCounter(currentRequestCounter => currentRequestCounter + 1)
    const thisRequestCounter = currentRequestCounter

    setNoMorePagesToLoad(false)

    const options = buildGetAllBooksOptions()
    options.page = 1
    options.limit = BOOKS_PER_PAGE
    // eslint-disable-next-line @typescript-eslint/naming-convention
    void apiAuthContext.apiClient?.getAllBooksWithOutgoingRequests(options).then(({ books, is_last_page_or_beyond }) => {
      const cancelled = currentRequestCounter !== thisRequestCounter

      if (cancelled) {
        return
      }

      precalculateThumbhashes(books)
      setBooks(books)
      setCurrentPage(1)
      setBookStatus('succeeded')
      setCurrentRequest(undefined)
      setNoMorePagesToLoad(is_last_page_or_beyond)

      flatList?.scrollToOffset({ offset: 0 })
    }).catch(reason => {
      const cancelled = currentRequestCounter !== thisRequestCounter

      if (cancelled) {
        return
      }

      setBookError(reason.toString())
      setBookStatus('failed')
    })
  }

  const doNextPageRequest = (): void => {
    if (currentRequest !== undefined) {
      return
    }

    setCurrentRequestCounter(currentRequestCounter + 1)
    const thisRequestCounter = currentRequestCounter

    setCurrentRequest('next-page')

    const options = buildGetAllBooksOptions()
    options.page = currentPage + 1
    options.limit = BOOKS_PER_PAGE
    // eslint-disable-next-line @typescript-eslint/naming-convention
    void apiAuthContext.apiClient?.getAllBooksWithOutgoingRequests(options).then(({ books: nextPageBooks, is_last_page_or_beyond }) => {
      const cancelled = currentRequestCounter !== thisRequestCounter

      if (cancelled) {
        return
      }

      if (nextPageBooks.length > 0) {
        setCurrentPage(currentPage + 1)

        precalculateThumbhashes(nextPageBooks)

        const concatOnlyUniqueBooks = [
          ...books,
          ...nextPageBooks
            .filter(
              nextPageBook => (
                books.findIndex(book => book.id === nextPageBook.id) === -1
              )
            )
        ]

        setBooks(concatOnlyUniqueBooks)
      }
      setNoMorePagesToLoad(is_last_page_or_beyond)
      setBookStatus('succeeded')
      setCurrentRequest(undefined)
    }).catch(reason => {
      const cancelled = currentRequestCounter !== thisRequestCounter

      if (cancelled) {
        return
      }

      setBookError(reason.toString())
      setBookStatus('failed')
    })
  }

  const precalculateThumbhashes = (books: BookWithOutgoingRequests[]): void => {
    const newThumbs: Record<string, Thumb> = {}

    books.forEach(book => {
      if (book.has_cover) {
        const thumb: Thumb = thumbhashes[book.id] ?? { done: false }
        const dataUrl = thumbHashToDataURL(Uint8Array.from(atob(book.cover_thumb_hash), c => c.charCodeAt(0)))
        newThumbs[book.id] = { ...thumb, thumbhash: dataUrl, done: true }
      }
    })

    setThumbhashes(thumbhashes => ({ ...thumbhashes, ...newThumbs }))
  }

  const reload = (): void => {
    setBookStatus('idle')
  }

  const refreshing = bookStatus === 'idle' || bookStatus === 'loading'

  const updateFilter = (): void => {
    const newFilter: AllBooksScreenFilter = {
      query,
      showFilter,
      sortBy,
      sortReverse,
      showOnlyAvailableBooks,
      showYourBooks
    }
    onUpdateFilter(newFilter)
    setDebouncedFilter(newFilter)
  }

  const isFilterDifferentFromDefault = ((): boolean => {
    const d = getDefaultAllBooksScreenFilter()
    return (
      showOnlyAvailableBooks !== d.showOnlyAvailableBooks ||
      showYourBooks !== d.showYourBooks ||
      sortBy !== d.sortBy ||
      sortReverse !== d.sortReverse
    )
  })()

  const onToggleFilter = (): void => {
    setShowFilter(!showFilter)
  }

  const onToggleShowYourOwnBooks = (): void => {
    setShowYourBooks(!showYourBooks)
  }

  const onToggleShowOnlyAvailableBooks = (): void => {
    setShowOnlyAvailableBooks(!showOnlyAvailableBooks)
  }

  const onClickSortBy = (sortType: SortByType): void => {
    if (sortBy === sortType) {
      setSortReverse(!sortReverse)
    } else {
      setSortBy(sortType)
      setSortReverse(false)
    }
  }

  const onResetFilter = (): void => {
    const d = getDefaultAllBooksScreenFilter()
    setShowOnlyAvailableBooks(d.showOnlyAvailableBooks)
    setShowYourBooks(d.showYourBooks)
    setSortBy(d.sortBy)
    setSortReverse(d.sortReverse)
    setShowFilter(false)
  }

  const onEndReached = (): void => {
    doNextPageRequest()
  }

  const SortChip = ({ sortType, title }: { sortType: SortByType, title: string }): JSX.Element => {
    const style = sortBy !== sortType ? { margin: 5, marginLeft: 0, borderWidth: 0, backgroundColor: 'transparent' } : {}
    const mode = sortBy === sortType ? 'flat' : 'outlined'

    return <Chip style={{ ...style, margin: 5 }} mode={mode}
          onPress={() => onClickSortBy(sortType)} icon={sortBy === sortType ? (sortReverse ? 'arrow-up' : 'arrow-down') : undefined}>{title}</Chip>
  }

  if (bookStatus === 'failed') {
    return <View>
      <View style={{ margin: 15 }}><Text>{bookError}</Text></View>
      <View style={{ margin: 15 }}><Text><Button onPress={reload} mode={'outlined'}>{trReload}</Button></Text></View>
    </View>
  }

  // const filteredAndSortedBooks = applyFilter(books, filter, apiAuthContext.authUser?.id)
  const filteredAndSortedBooks = books

  let footer: JSX.Element
  if (noMorePagesToLoad) {
    footer = <View style={{ alignItems: 'center', justifyContent: 'center', margin: 15, marginBottom: 30 }}><Text>{t('all_books.no_more_books_match_filter', 'No (more) books match filter')}</Text></View>
  } else {
    footer = <View style={{ alignItems: 'center', justifyContent: 'center', margin: 15, marginBottom: 30, flexDirection: 'row' }}><ActivityIndicator style={{ marginRight: 5 }} /><Text>{t('all_books.loading_more', 'loading more…')}</Text></View>
  }

  return (
    <>
      <View>
        <View style={{ flexDirection: 'row' }}>
          <Searchbar value={query} onChangeText={setQuery}
                     style={{ flex: 1, backgroundColor: 'transparent', borderWidth: 0 }}
                     elevation={0}></Searchbar>
          <IconButton icon={'filter'} onPress={onToggleFilter}
                      mode={isFilterDifferentFromDefault ? 'outlined' : undefined}></IconButton>
        </View>
        {showFilter && (
          <View style={{ borderBottomWidth: 1, borderBottomColor: theme.colors.border, marginBottom: 10 }}>
            <View style={{ padding: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
              <SortChip title={t('all_books.filter_sort_added', 'Added')} sortType={'added'}/>
              <SortChip title={t('all_books.filter_sort_title', 'Title')} sortType={'title'}/>
              <SortChip title={t('all_books.filter_sort_authors', 'Authors')} sortType={'authors'}/>
              <SortChip title={t('all_books.filter_sort_distance', 'Distance')} sortType={'distance'}/>
            </View>
            <View style={{
              paddingLeft: 15,
              borderBottomColor: '#eee'
            }}>

              <CheckBox label={t('all_books.show_only_available_books', 'Show only available books')} status={showOnlyAvailableBooks ? 'checked' : 'unchecked'}
                        onPress={onToggleShowOnlyAvailableBooks}/>
              <CheckBox label={t('all_books.show_only_your_books', 'Show your books')} status={showYourBooks ? 'checked' : 'unchecked'}
                        onPress={onToggleShowYourOwnBooks}/>

            </View>
            <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
              <Button compact={true} mode={'text'} onPress={onResetFilter}>{t('common.reset', 'Reset')}</Button>
            </View>
          </View>
        )}
      </View>
      {showLocationPermissionBox && locationPermissionStatus === 'undetermined' &&
        <LocationPermissionBox onLocationPermissionChange={onLocationPermissionChange}
                               onClose={() => setShowLocationPermissionBox(false)}/>
      }
      <FlatList ref={(bla) => { setFlatList(bla) }}
                data={filteredAndSortedBooks}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={reload}/>}
                initialNumToRender={10}
                // getItemLayout={(data, index) => {
                //   return { length: ITEM_HEIGHT, offset: ITEM_HEIGHT * index, index }
                // }}
                extraData={{ thumbhashes, images }}
                ListFooterComponent={footer}
                onEndReached={onEndReached}
                onEndReachedThreshold={0.5}
                onViewableItemsChanged={onViewableItemsChanged.current}
                renderItem={({ item }) => <MemoBookListItem book={item} imageDataUrl={getThumbDataUrl(item)}/>}/>
      {
        Platform.OS === 'web'
          ? <Button onPress={() => reload()} mode={'outlined'} style={{ margin: 16 }} disabled={busy}
                    loading={busy}>{trReload}</Button>
          : <></>
      }
    </>
  )
}
