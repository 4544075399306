import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translation_en from './locales/en/translation.json'
import translation_nl from './locales/nl/translation.json'

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      en: {
        translation: translation_en
      },
      nl: {
        translation: translation_nl
      }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  })
