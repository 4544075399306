import {
  getActiveIncomingRequest,
  MyBookWithIncomingRequests,
  waitingForBorrowerAction,
  waitingForOwnerAction
} from '../book'
import React, { FC, memo } from 'react'
import Ionicons from '@expo/vector-icons/Ionicons'
import { Image, Text, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { List } from 'react-native-paper'
import { useCustomTheme } from '../../../app/themes'
import { useTranslation } from 'react-i18next'

export interface BookListItemProps {
  book: MyBookWithIncomingRequests
  imageDataUrl: string
}

export const MyBookListItem: FC<BookListItemProps> = ({ book, imageDataUrl }) => {
  const activeRequest = getActiveIncomingRequest(book)
  const navigation = useNavigation()

  let activeRequestBubble: JSX.Element = <></>

  if (activeRequest != null) {
    if (waitingForBorrowerAction(activeRequest)) {
      activeRequestBubble = <Ionicons name={'ellipsis-horizontal-outline'} />
    } else if (waitingForOwnerAction(activeRequest)) {
      activeRequestBubble = <Ionicons name={'ellipse'} color={'red'}/>
    }
  }

  const onPress = (): void => {
    if (book.is_owned_by_me) {
      // @ts-expect-error
      navigation.navigate('Detail', { book })
    } else {
      // @ts-expect-error
      navigation.navigate('On Loan Detail', { book })
    }
  }

  return <List.Item
    left={() => left(imageDataUrl)}
    title={() => title(book, activeRequestBubble)}
    description={() => description(book)}
    onPress={onPress}
    right={() => right(book)}/>
}

const left = (imageDataUrl): JSX.Element => {
  return <View style={{ marginLeft: 15, justifyContent: 'flex-start' }}>
    <View style={{ width: 36, height: 36 }} >
      <Image
        source={{ uri: imageDataUrl }}
        resizeMode={'contain'}
        style={{ width: undefined, height: undefined, flex: 1 }}/>
    </View>
  </View>
}

const rightContent = (item: MyBookWithIncomingRequests): JSX.Element => {
  const { t } = useTranslation()
  if (item.is_owned_by_me && item.is_on_loan) {
    return <Text style={{ color: 'navy' }}>{t('my_books.out_on_loan', 'Out on loan')}</Text>
  }
  return <></>
}

const rentableEye = (item: MyBookWithIncomingRequests): JSX.Element => {
  return <Text style={{ color: 'gray' }}>{!item.is_rentable && <Ionicons name={'eye-off-outline'} size={16}/>}</Text>
}

const right = (item: MyBookWithIncomingRequests): JSX.Element => {
  return <View style={{ marginLeft: 15, justifyContent: 'flex-start' }}>
    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>{rightContent(item)}</View>
    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>{rentableEye(item)}</View>
  </View>
}

const title = (item: MyBookWithIncomingRequests, activeRequestBubble): JSX.Element => {
  return <Text>{item.name} {activeRequestBubble}</Text>
}

const description = (item: MyBookWithIncomingRequests): JSX.Element => {
  const theme = useCustomTheme()
  const { t } = useTranslation()

  let authors = ''
  if (item.authors.length > 0) {
    authors = item.authors.join(', ').trim()
  }
  if (authors === '') {
    authors = t('my_books.unknown_author_name', 'Unknown')
  }

  let publishDate = ''
  if (item.publish_date != null && item.publish_date.length > 0) {
    publishDate = ` (${item.publish_date})`
  }

  return <Text style={{ color: theme.colors.secondary }}>{authors}{publishDate}</Text>
}

export const MemoMyBookListItem = memo(MyBookListItem)
