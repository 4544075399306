import React, { FC, useContext, useEffect, useState } from 'react'
import { BookWithOutgoingRequests, canReserveBook, getBookOwnerNameFromBookRequestInfo } from '../book'
import { OutgoingRequest, PickupLocation, PickupSlot } from '../../../client/data-contracts'
import { ApiAuthContext } from '../../../app/api_auth_context'
import { ScrollView, Text, View } from 'react-native'
import { getCountryName } from '../countries'
import { Button, RadioButton } from 'react-native-paper'
import { parseRFC3339DateTime, strfmtDateTime } from '../../../app/dates'
import { useNavigation, useRoute } from '@react-navigation/native'
import Ionicons from '@expo/vector-icons/Ionicons'
import { getDistanceLowResolutionKm, isDistanceKnownAndRelevant } from '../../location/location'
import { useCustomTheme } from '../../../app/themes'
import { BigCoverImage } from '../../widgets/BigCoverImage'
import { useTranslation } from 'react-i18next'

export interface DetailBooksScreenProps {
  onUpdateFromDetailScreen: () => void
}

export const DetailBooksScreen: FC<DetailBooksScreenProps> = ({ onUpdateFromDetailScreen }) => {
  const { t } = useTranslation()

  const trReserve = t('all_books_detail.reserve', 'Reserve')
  const trReservedText = t('all_books_detail.reserved_message', 'Reserved! Owner has received a message. Once they accept, you can agree on a pickup time and location.')
  const trCancel = t('common.cancel', 'Cancel')

  const navigation = useNavigation()
  const route = useRoute()
  // @ts-expect-error
  const book: BookWithOutgoingRequests = route.params?.book

  useEffect(() => {
    navigation.setOptions({ title: book.name, headerTitleStyle: { ellipsizeMode: 'tail' } })
  }, [])

  const [outgoingBookRequest, setOutgoingBookRequest] = useState<OutgoingRequest | null>(null)
  const [bookOwnerName, setBookOwnerName] = useState('')

  const { apiClient, authUser } = useContext(ApiAuthContext)

  const [errorMessage, setErrorMessage] = useState('')

  const [pickupLocation, setPickupLocation] = useState<PickupLocation | null>(null)

  const reloadOutgoingBookRequest = (): void => {
    void apiClient?.getActiveOutgoingRequestForBook(book)
      .then(value => {
        setOutgoingBookRequest(value)

        if (value !== null) {
          void apiClient?.bookRequestInfo(value.id)
            .then(value2 => setBookOwnerName(getBookOwnerNameFromBookRequestInfo(value2)))
            .catch(() => setBookOwnerName(''))
        }

        if (value !== null && ['has-available-pickup-slots', 'has-chosen-pickup-slot'].includes(value.status)) {
          void apiClient?.getSingletonPickupLocationForBookRequest(value?.id ?? '')
            .then(value2 => setPickupLocation(value2))
            .catch(() => setPickupLocation(null))
        }
      })
  }

  useEffect(() => reloadOutgoingBookRequest(), [])

  const reserve = (): void => {
    onUpdateFromDetailScreen()
    apiClient?.requestBook(book).then(() => {
      setErrorMessage('')
      reloadOutgoingBookRequest()
    }).catch(reason => {
      setErrorMessage(reason.toString())
      reloadOutgoingBookRequest()
    })
  }

  const cancel = (): void => {
    onUpdateFromDetailScreen()
    // @ts-expect-error
    apiClient?.cancelBookRequest(outgoingBookRequest).then(() => {
      setErrorMessage('')
      reloadOutgoingBookRequest()
    }).catch(reason => {
      setErrorMessage(reason.toString())
      reloadOutgoingBookRequest()
    })
  }

  const [chosenPickupSlotIndex, setChosenPickupSlotIndex] = useState(0)

  const save = (): void => {
    onUpdateFromDetailScreen()
    const slot: PickupSlot = outgoingBookRequest?.available_pickup_slots?.[chosenPickupSlotIndex] ?? {
      from: '',
      until: ''
    }
    apiClient?.choosePickupSlot(outgoingBookRequest?.id ?? '', slot)
      .then(() => console.log('okay'))
      .catch(reason => console.error(reason))
      .finally(() => reloadOutgoingBookRequest())
  }

  const saveReturnSlot = (): void => {
    onUpdateFromDetailScreen()
    const slot: PickupSlot = outgoingBookRequest?.available_pickup_slots?.[chosenPickupSlotIndex] ?? {
      from: '',
      until: ''
    }
    apiClient?.chooseReturnSlot(outgoingBookRequest?.id ?? '', slot)
      .then(() => console.log('okay'))
      .catch(reason => console.error(reason))
      .finally(() => reloadOutgoingBookRequest())
  }

  const noFeasiblePickupSlots = (): void => {
    onUpdateFromDetailScreen()
    apiClient?.noFeasiblePickupSlots(outgoingBookRequest?.id ?? '')
      .then(() => console.log('okay'))
      .catch(reason => console.error(reason))
      .finally(() => reloadOutgoingBookRequest())
  }

  const noFeasibleReturnSlots = (): void => {
    onUpdateFromDetailScreen()
    apiClient?.noFeasibleReturnSlots(outgoingBookRequest?.id ?? '')
      .then(() => console.log('okay'))
      .catch(reason => console.error(reason))
      .finally(() => reloadOutgoingBookRequest())
  }

  interface PickupLocationProps {
    name: string
    pickupLocation: PickupLocation | null
  }

  const PickupLocation: FC<PickupLocationProps> = ({ pickupLocation, name }) => {
    if (pickupLocation !== null) {
      return <>
        <Text>{name}</Text>
        <Text>{pickupLocation.address}</Text>
        <Text>{pickupLocation.postal_code} {pickupLocation.locality}</Text>
        <Text>{getCountryName(pickupLocation.country_alpha_3)}</Text>
        {pickupLocation.notes.length > 0
          ? <Text
            style={{ fontStyle: 'italic', paddingTop: 5 }}>{pickupLocation.notes}</Text>
          : <></>}
      </>
    } else {
      return <Text>{t('all_books_detail.unknown_location_message', 'Unknown location, book owner needs to specify.')}</Text>
    }
  }

  const DetailPageWrapper: FC<any> = ({ children }) => {
    return <ScrollView>
      <View style={{ padding: 15 }}>
        {children}
      </View>
    </ScrollView>
  }

  interface ErrorMessageProps {
    message?: string
  }

  const ErrorMessage: FC<ErrorMessageProps> = ({ message: string }) => {
    if (errorMessage !== undefined && errorMessage !== '') {
      return <Text style={{ color: 'red', marginTop: 15 }}>{errorMessage}</Text>
    } else {
      return <></>
    }
  }

  let authors = t('all_books_detail.unknown_authors', 'Unknown Author(s)')
  if (book.authors.length > 0) {
    authors = book.authors.join(', ').trim()
    if (authors === '') {
      authors = t('all_books_detail.unknown_authors', 'Unknown Author(s)')
    }
  }

  const theme = useCustomTheme()

  const bookInfo = <View style={{ alignItems: 'center', justifyContent: 'center', marginBottom: 15 }}>
     <BigCoverImage
      book={book}
      squareSize={200}/>
    <Text style={{ marginTop: 15, fontSize: 18 }}>{authors}</Text>
    {book.publish_date !== '' && <Text>{t('all_books_detail.published', 'Published {{publish_date}}', { publish_date: book.publish_date })}</Text>}
    <Text style={{ marginBottom: 5 }}>{t('all_books_detail.isbn', 'ISBN: {{isbn}}', { isbn: book.isbn })}</Text>
    {isDistanceKnownAndRelevant(book) && <Text style={{ color: theme.colors.secondary }}><Ionicons name={'location-outline'} size={16}/> {getDistanceLowResolutionKm(book)}</Text>}
  </View>

  const RequestBoxWrapper = View

  const getRequestBox = (): JSX.Element => {
    if (outgoingBookRequest === null) {
      if (canReserveBook(book, authUser?.id ?? '')) {
        return (<RequestBoxWrapper>
          <Button onPress={() => reserve()} mode={'outlined'}>{trReserve}</Button>
          <ErrorMessage message={errorMessage}/>
        </RequestBoxWrapper>)
      } else {
        return <RequestBoxWrapper><Text>{t('all_books_detail.cannot_reserve_book_message', 'Cannot reserve book (e.g. you own this book yourself, or it is already on loan)')}</Text></RequestBoxWrapper>
      }
    } else if (outgoingBookRequest.status === 'pending') {
      return <RequestBoxWrapper>
        <Text>{trReservedText}</Text>
        <Button onPress={() => cancel()} mode={'outlined'} style={{ marginTop: 15 }}>{trCancel}</Button>
        <ErrorMessage message={errorMessage}/>
      </RequestBoxWrapper>
    } else if (outgoingBookRequest.status === 'accepted') {
      return <RequestBoxWrapper>
        <Text>{t('all_books_detail.request_accepted_message', 'Your request has been accepted. The owner will provide you with one or more timeslots for pickup.')}</Text>
      </RequestBoxWrapper>
    } else if (outgoingBookRequest.status === 'no-feasible-pickup-slots') {
      return <RequestBoxWrapper>
        <Text>{t('all_books_detail.no_feasible_pickup_message', 'Sorry that you are not available on any of the provided timeslots. The owner will provide you with one or more different timeslots for pickup.')}</Text>
      </RequestBoxWrapper>
    } else if (outgoingBookRequest.status === 'has-available-pickup-slots') {
      return <RequestBoxWrapper>
        <Text>{t('all_books_detail.you_can_pick_book_up_at', 'You can pick the book up at:')}</Text>
        <View style={{ padding: 15 }}><PickupLocation name={bookOwnerName}
                                                      pickupLocation={pickupLocation}></PickupLocation></View>
        <Text style={{ marginTop: 15 }}>{t('all_books_detail.choose_pickup_slot', 'Choose a pickup slot:')}</Text>
        <RadioButton.Group onValueChange={(value) => { setChosenPickupSlotIndex(parseInt(value)) }}
                           value={chosenPickupSlotIndex.toString()}>
          {outgoingBookRequest.available_pickup_slots?.map((slot, index) => {
            const from = strfmtDateTime(parseRFC3339DateTime(slot.from))
            const until = strfmtDateTime(parseRFC3339DateTime(slot.until))
            return (
              <RadioButton.Item labelStyle={{ fontSize: 15 }} position={'leading'} key={index} value={index.toString()}
                                label={`${from} - ${until}`}/>
            )
          })}
        </RadioButton.Group>
        <Button onPress={() => save()} mode={'contained'}>Save</Button>
        <Button onPress={noFeasiblePickupSlots} mode={'outlined'} style={{ marginTop: 15 }}>{t('all_books_detail.none_work_for_me', 'None of these work for me')}</Button>
      </RequestBoxWrapper>
    } else if (outgoingBookRequest.status === 'has-chosen-pickup-slot' && outgoingBookRequest.chosen_pickup_slot !== undefined && outgoingBookRequest.chosen_pickup_slot !== null) {
      const from = strfmtDateTime(parseRFC3339DateTime(outgoingBookRequest.chosen_pickup_slot.from))
      const until = strfmtDateTime(parseRFC3339DateTime(outgoingBookRequest.chosen_pickup_slot.until))
      return <RequestBoxWrapper>
        <Text>{t('all_books_detail.you_can_pick_book_up_at', 'You can pick the book up at:')}</Text>
        <View style={{ padding: 15 }}><PickupLocation name={bookOwnerName}
                                                      pickupLocation={pickupLocation}></PickupLocation></View>
        <Text style={{ paddingTop: 15 }}>{t('all_books_detail.chosen_pickup_slot', 'Chosen pickup slot:')}</Text>
        <View
          style={{ padding: 15 }}><Text>{from} - {until}</Text></View>
        <Text>{t('all_books_detail.please_pickup_between_time_window', 'Please pickup the book within this time window :)')}</Text>
      </RequestBoxWrapper>
    } else if (outgoingBookRequest.status === 'picked-up') {
      return <RequestBoxWrapper>
        <Text>{t('all_books_detail.book_picked_up_message', 'The book has been picked up by someone (maybe you). Wait until it is returned to the owner, before you can make a reservation.')}</Text>
      </RequestBoxWrapper>
    } else if (outgoingBookRequest.status === 'no-feasible-return-slots') {
      return <RequestBoxWrapper>
        <Text>{t('all_books_detail.no_feasible_return_message', 'Sorry that you are not available on any of the provided timeslots. The owner will provide you with one or more different timeslots for return.')}</Text>
      </RequestBoxWrapper>
    } else if (outgoingBookRequest.status === 'has-available-return-slots') {
      return <RequestBoxWrapper>
        <Text>{t('all_books_detail.you_can_return_book_to', 'You can return the book to:')}</Text>
        <View style={{ padding: 15 }}><PickupLocation name={bookOwnerName}
                                                      pickupLocation={pickupLocation}></PickupLocation></View>
        <Text style={{ marginTop: 15 }}>{t('all_books_detail.choose_return_time', 'Choose a return time:')}</Text>
        <RadioButton.Group onValueChange={(value) => { setChosenPickupSlotIndex(parseInt(value)) }}
                           value={chosenPickupSlotIndex.toString()}>
          {outgoingBookRequest.available_pickup_slots?.map((slot, index) => {
            const from = strfmtDateTime(parseRFC3339DateTime(slot.from))
            const until = strfmtDateTime(parseRFC3339DateTime(slot.until))
            return (
              <RadioButton.Item labelStyle={{ fontSize: 15 }} position={'leading'} key={index} value={index.toString()}
                                label={`${from} - ${until}`}/>
            )
          })}
        </RadioButton.Group>
        <Button onPress={() => saveReturnSlot()} mode={'contained'}>{t('common.save', 'Save')}</Button>
        <Button onPress={noFeasibleReturnSlots} mode={'outlined'} style={{ marginTop: 15 }}>{t('all_books_detail.none_work_for_me', 'None of these work for me')}</Button>
      </RequestBoxWrapper>
    } else if (outgoingBookRequest.status === 'has-chosen-return-slot' && outgoingBookRequest.chosen_pickup_slot !== undefined && outgoingBookRequest.chosen_pickup_slot !== null) {
      const from = strfmtDateTime(parseRFC3339DateTime(outgoingBookRequest.chosen_pickup_slot.from))
      const until = strfmtDateTime(parseRFC3339DateTime(outgoingBookRequest.chosen_pickup_slot.until))
      return <RequestBoxWrapper>
        <Text>{t('all_books_detail.you_can_return_book_to', 'You can return the book to:')}</Text>
        <View style={{ padding: 15 }}><PickupLocation name={bookOwnerName}
                                                      pickupLocation={pickupLocation}></PickupLocation></View>
        <Text style={{ paddingTop: 15 }}>{t('all_books_detail.chosen_return_slot', 'Chosen return slot:')}</Text>
        <View
          style={{ padding: 15 }}><Text>{from} - {until}</Text></View>
        <Text>{t('all_books_detail.please_return_between_time_window', 'Please return the book within this time window :)')}</Text>
      </RequestBoxWrapper>
    } else {
      return <RequestBoxWrapper></RequestBoxWrapper>
    }
  }

  return <DetailPageWrapper>
    {bookInfo}
    {getRequestBox()}
  </DetailPageWrapper>
}
